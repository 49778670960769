// @flow

import theme, { darkTheme, type ThemePropsType } from "../../components/theme";

export type SetThemeAction = {
  type: "SET_THEME",
  payload: ThemePropsType
};

var initialTheme = "light";
var initialState = theme;

if (window && window.localStorage) {
  initialTheme = window.localStorage.getItem("theme") || "light";
  initialState = initialTheme === "dark" ? darkTheme : theme;
}

export default (
  state = initialState,
  action: SetThemeAction
): ThemePropsType => {
  var newTheme = state;
  switch (action.type) {
    case "SET_THEME": {
      newTheme = action.payload;
      break;
    }
    case "TOGGLE_THEME": {
      if (state.name === "dark") newTheme = theme;
      else newTheme = darkTheme;
      break;
    }
    default:
  }
  if (newTheme.name !== state.name) {
    window.localStorage.setItem("theme", newTheme.name);
  }

  return newTheme;
};
