// @flow

import React from "react";
import Grid from "styled-components-grid";
import { BigSpinner } from "../components/spinner";
import { Body } from "../components/text";
import { ItemsCenteredContainer } from "../components/container";

type ProcessingPaymentPageType = {
  messages: {
    processingPayment: string
  }
};
const ProcessingPaymentPage = ({ messages }: ProcessingPaymentPageType) => (
  <ItemsCenteredContainer>
    <Grid halign="center" width="100%">
      <Grid.Unit size={1 / 3}>
        <Body align="center">
          <BigSpinner />
        </Body>
        <Body align="center">{messages.processingPayment}</Body>
      </Grid.Unit>
    </Grid>
  </ItemsCenteredContainer>
);

export default ProcessingPaymentPage;
