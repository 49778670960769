// @flow

import styled from "styled-components";
import type { ThemePropsType } from "./theme";

export type TextPropsType = {
  align?: string,
  theme?: ThemePropsType,
};

const Text = styled.p`
  font-family: ${({ theme }: { theme: ThemePropsType }) => theme.font.family};
  color: ${({ theme }: { theme: ThemePropsType }) => theme.color.text};
  text-align: ${(props: TextPropsType) => props.align || "left"};
  padding: 0;
  margin: 0;
  line-height: 1.7em;
`;

const textWithTheme = (type: string, extra?: string) => styled(Text)`
  font-size: ${({ theme }: { theme: ThemePropsType }) => theme.font[type].size};
  font-weight: ${({ theme }: { theme: ThemePropsType }) =>
    theme.font[type].weight};
  ${extra || ""};
`;

export const Title = textWithTheme("title", `margin: 2em 0 2em 0;`);

export const Heading = textWithTheme("heading");

export const Body = textWithTheme("body");

export const Subtitle = textWithTheme("subtitle");

export const Footer = textWithTheme("footer");

export const Description = styled(Body)`
  margin: 10px 0;
  font-size: 0.9em;
  color: #797979;
`;
