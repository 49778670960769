// @flow

import type { TestType } from "../reducers/tests";

export const setRunningTest = (test: TestType) => ({
  type: "SET_TEST_IN_PROGRESS",
  payload: test
});

export const setTestsHistory = (history: Array<TestType>) => ({
  type: "SET_TESTS_HISTORY",
  payload: history
});
