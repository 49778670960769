// @flow
import React from "react";

// UI Dependecies
import { Body } from "../components/text";
import { AppIcon } from "../components/img";
import { Container, RaisedContainer } from "../components/container";
import styled, { keyframes } from "styled-components";
import { withOpacity } from "../components/theme";
import { fadeInUp } from "react-animations";

// Container Dependencies
import { withRouter } from "react-router-dom";
import { withProps } from "recompose";
import { setProducts } from "../redux/actions/products";
import type { AppStateType } from "../redux/reducers";
import { connect } from "react-redux";
import DataLoader from "../components/data_loader";
import Space from "../components/spacing";
import { stringWithFormat } from "../utils/locale";

const ProductContainer = styled(Container)`
  cursor: pointer;
  flex: 0.1;
  min-width: 220px;
  max-width: 240px;
  :hover {
    background-color: ${(props) => withOpacity(props.theme.color.primary, 0.1)};
  }
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 0;
  min-height: 280px;
  margin: 10px 5px;
  justify-content: stretch;

  @media only screen and (min-device-width: 320px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    margin: 10px 0;
    width: 100%;
    min-height: inherit;
    min-width: 90%;
    max-width: 100%;
  }
`;
const ProductIcon = styled(AppIcon)`
  width: 60px;
  height: 60px;
  align-self: flex-start;
  margin-left: 20px;
  margin-top: -30px;
  margin-bottom: 0.5em;
`;
const ProductTitle = styled(Body)`
  padding: 20px;
  font-size: 0.8em;
  text-align: left;
  align-self: flex-start;
  max-width: 100%;
`;
const PageTitle = styled(Body)`
  font-size: 2.5em;
  margin-top: 3em;
  padding: 0 20px 0 20px;
`;

const appearAnimation = keyframes`${fadeInUp}`;
const ProductsContainer = styled(Container)`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 2em;
  padding-top: 2em;
  animation: 1s ${appearAnimation};

  @media only screen and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) {
    flex-direction: column;
    width: 90%;
    margin-bottom: 2em;
  }
`;
const PromoImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 150px;
  border-radius: 8px 8px 0 0;
`;

type ProductType = {
  icon: string,
  title: string,
  path: string,
  name: string
};
type ProductsPagePropsType = {
  products: Array<ProductType>,
  onProductClick: (product: ProductType) => void,
  onCloseModalClick: () => void,
  messages: {
    productsTitle: string,
    title: string,
    welcomeTo: string
  }
};

export const ProductsPage = (props: ProductsPagePropsType) => {
  const { products, onProductClick, messages } = props;

  return (
    <Container>
      <RaisedContainer>
        <PageTitle align="center">
          {stringWithFormat(messages.welcomeTo, {
            $title: messages.title
          })}
        </PageTitle>
        <Body align="center">{messages.productsTitle}</Body>
        <Space v={50} />
        <ProductsContainer>
          {products.map((p, i) => (
            <ProductContainer
              padding={"10px"}
              key={i}
              onClick={() => onProductClick(p)}>
              <PromoImage
                src={`https://itheorie.ch/images/${p.name}_promo.png`}
              />
              <ProductIcon src={p.icon} />
              <ProductTitle>{p.title}</ProductTitle>
            </ProductContainer>
          ))}
        </ProductsContainer>
      </RaisedContainer>
    </Container>
  );
};
ProductsPage.defaultProps = {
  onProductClick: () => {},
  onCloseModalClick: () => {}
};

/**
 * ======================================================
 * Section below is to enhance the ProductsPage component
 * ======================================================
 */

// add user interaction handlers
const WithHandlers = withProps((props) => ({
  onCloseModalClick: (e) => {
    e.preventDefault();
    props.history.goBack();
  },
  onProductClick: (product) => {
    if (props.goToLogin) {
      props.history.push(`${product.path}`);
    } else {
      props.history.push(`${product.path}/buy`);
    }
  }
}))(ProductsPage);

const ProductsWithData = (props) => (
  <DataLoader
    component={WithHandlers}
    pathname="/products"
    onDataFetch={(data) => props.dispatch(setProducts(data))}
    passDataToComponent={false}
    {...props}
  />
);

// get the products props from redux
const mapStateToProps = (state: AppStateType) => ({
  products: state.products.map((p) => ({
    ...p,
    title: p.title[state.language.selected]
  })),
  messages: state.language.messages
});

// add history props from react-router
const WithRouter = withRouter(connect(mapStateToProps)(ProductsWithData));

export default WithRouter;
