// @flow

import * as React from "react";

import { FavouriteButton, ReportButton } from "./button";
import { Row } from "./container";
import RightWrong from "./right_or_wrong";
import type { FlavourState } from "../redux/reducers/flavour";

import micromarkdown from "micromarkdown";
import { smallScreenQuery, wideScreenQuery } from "./screen";
import Media from "react-media";

import * as LC from "../components/learn_card/components";
import { Title, Body } from "./text";

export type AnswerType = {
  answer_id: string,
  names: Object, // the text of the answer {en: "", de: ""}
  selected: boolean,
  isCorrect: boolean,
  isInputAnswer: boolean,
  inputAnswer: string
};
export type QuestionStateType = {
  code: string,
  names: Object, // the question text, {en: "", de: ""}
  texts: Object, // the question explannation {en: "", de: ""},
  isNumberAnswer: boolean,
  typecode: string // V for video type
};

type LearnCardMessageType = {
  addToFavourite: string,
  report: string,
  back: string,
  finish: string,
  check: string,
  next: string,
  explanation: string,
  correctAnswer: string,
  yourAnswer: string,
  explanationWillShowHere: string,
  reportProblemSubject: string,
  reportProblemBody: string,
  noExplanation: string,
  teacherPOV: boolean
};

export type LearnCardPropsType = {
  flavour: FlavourState,
  index: number,
  total: number,
  image?: string,
  question: QuestionStateType,
  answers: Array<AnswerType>,
  messages: LearnCardMessageType,
  selectedLanguage: string,
  userIsRight: boolean,
  isInTestResult: boolean,
  isInRunningTest: boolean,
  isSubmittingAnswer: boolean,
  isFavourite: boolean,
  isSingleAnswer: boolean,
  isInputAnswer: boolean,
  category: {
    code: string,
    flavour: string,
    names: Object
  },
  showExplanation: boolean,
  sessionId: string,
  isSubmittingAnswer: boolean,
  isRunningTest: boolean,
  remaining: number,
  nextPath?: string,
  previousPath?: string,
  resultURL?: string,
  indices: Array<{
    index: number,
    clickable: boolean,
    answered: boolean
  }>,
  onClickAnswer?: (answer: AnswerType) => void,
  onChangeInputAnswer?: (answer: string) => void,
  onClickImage?: () => void,
  onClickFavourite?: (
    questionId: string,
    categoryCode: string,
    index: number
  ) => void,
  onClickBack?: (index: number, questionId: string, sessionId: string) => void,
  onClickCorrect?: (
    answers: Array<AnswerType>,
    questionId: string,
    category: string,
    sessionId: string,
    currentIndex: number,
    isInRunningTest: boolean
  ) => void,
  onClickNext?: (
    currentIndex: number,
    questionId: string,
    sessionId: string
  ) => void,
  onClickReport?: (
    code: string,
    messages: Object,
    selectedLanguage: string,
    country: string,
    name: string
  ) => void,
  setIsSubmittingAnswer: (isSubmittingAnswer: boolean) => void,
  onFetchQuestion: (question: QuestionStateType) => void,
  history: any
};
export const LearnCard = ({
  question: { code, names, texts, typecode },
  flavour,
  index,
  total,
  image,
  category,
  answers,
  sessionId,
  isFavourite,
  isSingleAnswer,
  messages,
  userIsRight,
  showExplanation,
  isSubmittingAnswer,
  isInTestResult,
  isInRunningTest,
  onClickAnswer,
  onClickImage,
  onClickFavourite,
  onClickBack,
  onClickCorrect,
  onClickNext,
  onClickReport,
  onChangeInputAnswer,
  selectedLanguage = "en",
  teacherPOV
}: LearnCardPropsType) => {
  const isLast = index === total - 1;
  const buttonText = !isLast
    ? showExplanation || isInRunningTest
      ? messages.next
      : messages.check
    : showExplanation || isInRunningTest
    ? messages.finish
    : messages.check;
  const explanationComponent = () => (
    <React.Fragment>
      <Title>{messages.explanation}</Title>
      <LC.ExplanationText
        dangerouslySetInnerHTML={{
          __html: micromarkdown
            .parse(texts[selectedLanguage] || messages.noExplanation)
            .replace(/\\n/g, "<br /><br />")
        }}
      />
    </React.Fragment>
  );
  return (
    <LC.Container isInTestResult={isInTestResult}>
      <LC.QuestionContainer isInTestResult={isInTestResult || isInRunningTest}>
        <LC.Header>
          <LC.QuestionCode>{code}</LC.QuestionCode>
          {!teacherPOV && (
            <Row center>
              <LC.CheckmarkTooltip
                content={messages.addToFavourite}
                placement="left">
                <FavouriteButton
                  selected={isFavourite}
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    if (onClickFavourite) {
                      onClickFavourite(
                        code,
                        category ? category.code : "",
                        index
                      );
                    }
                  }}
                />
              </LC.CheckmarkTooltip>
              <LC.CheckmarkTooltip content={messages.report}>
                <ReportButton
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    if (onClickReport) {
                      onClickReport(
                        code,
                        messages,
                        selectedLanguage,
                        flavour.country,
                        flavour.name
                      );
                    }
                  }}
                />
              </LC.CheckmarkTooltip>
            </Row>
          )}
        </LC.Header>

        {image && image.length > 0 && typecode !== "V" && (
          <LC.QuestionImage src={image} onClick={onClickImage} />
        )}

        {image && image.length > 0 && typecode === "V" && (
          <LC.QuestionVideo src={image} onContextMenu={e => e.preventDefault()}>
            Sorry, your browser doesn't support embedded videos.
          </LC.QuestionVideo>
        )}

        <LC.AnswersContainer>
          {showExplanation && !isInRunningTest && (
            <RightWrong values={{ userIsRight: userIsRight }} />
          )}
          <LC.QuestionText>{names[selectedLanguage]}</LC.QuestionText>
          {answers.map((a, i) => {
            if (!a.number) {
              return (
                <LC.Answer
                  key={i}
                  onClick={() => {
                    if (
                      isInRunningTest ||
                      (!isInTestResult &&
                        !showExplanation &&
                        !isSubmittingAnswer &&
                        !teacherPOV)
                    ) {
                      if (onClickAnswer) {
                        onClickAnswer(a);
                      }
                    }
                  }}
                  isCorrect={a.isCorrect}
                  showExplanation={showExplanation && !isInRunningTest}>
                  <input
                    type={isSingleAnswer ? "radio" : "checkbox"}
                    checked={!!a.selected}
                    onChange={() =>
                      !isInTestResult &&
                      !showExplanation &&
                      !isSubmittingAnswer &&
                      !teacherPOV &&
                      onClickAnswer &&
                      onClickAnswer(a)
                    }
                  />
                  <Body>
                    {a.names[selectedLanguage].indexOf("[IMAGE]") === 0 ? (
                      <LC.AnswerImage
                        src={a.names[selectedLanguage].replace("[IMAGE]", "")}
                      />
                    ) : (
                      a.names[selectedLanguage]
                    )}
                  </Body>
                  {(teacherPOV ||
                    (showExplanation && !isInRunningTest && a.isCorrect)) && (
                    <LC.CheckmarkTooltip content={messages.correctAnswer}>
                      <LC.CheckImage hidden={!a.isCorrect} />
                    </LC.CheckmarkTooltip>
                  )}
                </LC.Answer>
              );
            } else {
              const numberAnswerString = `(( ${a.number} ))`;
              return (
                <LC.NumberAnswerContainer key={i}>
                  <input
                    type="text"
                    value={a.inputAnswer || ""}
                    onChange={e => onChangeInputAnswer(e.target.value)}
                    placeholder={a.names[selectedLanguage].replace(
                      numberAnswerString,
                      "..."
                    )}
                  />
                  {showExplanation && !isInRunningTest && (
                    <div>{a.names[selectedLanguage]}</div>
                  )}
                </LC.NumberAnswerContainer>
              );
            }
          })}
        </LC.AnswersContainer>
        {!isInTestResult && !teacherPOV && (
          <LC.ButtonsRow>
            {index > 0 && (
              <LC.LearnButton
                disabled={isSubmittingAnswer}
                isInProgress={isSubmittingAnswer}
                secondary
                onClick={() => onClickBack && onClickBack(code, index, "")}>
                {messages.back}
              </LC.LearnButton>
            )}
            <LC.LearnButton
              disabled={isSubmittingAnswer}
              isInProgress={isSubmittingAnswer}
              stretch={index === 0}
              onClick={e => {
                e.preventDefault();
                if (isInRunningTest) {
                  onClickCorrect &&
                    onClickCorrect(
                      answers,
                      code,
                      category.code,
                      sessionId,
                      index,
                      true
                    );
                } else {
                  if (!showExplanation) {
                    onClickCorrect &&
                      onClickCorrect(
                        answers,
                        code,
                        category.code,
                        sessionId,
                        index,
                        false
                      );
                  } else {
                    onClickNext && onClickNext(index, code, sessionId);
                  }
                }
              }}>
              {buttonText}
            </LC.LearnButton>
          </LC.ButtonsRow>
        )}
        {isInTestResult && (
          <LC.ExplanationContainerInTestResult center={!showExplanation}>
            {!showExplanation && <LC.LockImage />}
            {showExplanation && explanationComponent()}
          </LC.ExplanationContainerInTestResult>
        )}
      </LC.QuestionContainer>
      {!isInTestResult && !isInRunningTest && (
        <Media query={wideScreenQuery}>
          {matches =>
            matches && (
              <LC.ExplanationContainer center={!showExplanation}>
                {!showExplanation && !teacherPOV && (
                  <LC.LockContainer>
                    <LC.LockImage />
                    <Body>{messages.explanationWillShowHere}</Body>
                  </LC.LockContainer>
                )}
                {(showExplanation || teacherPOV) && (
                  <LC.ExplanationTextContainer>
                    {explanationComponent()}
                  </LC.ExplanationTextContainer>
                )}
              </LC.ExplanationContainer>
            )
          }
        </Media>
      )}

      <Media query={smallScreenQuery}>
        {matches =>
          matches && showExplanation && !isInTestResult && !isInRunningTest ? (
            <LC.ExplanationContainer>
              <LC.ExplanationTextContainer>
                {explanationComponent()}
              </LC.ExplanationTextContainer>
            </LC.ExplanationContainer>
          ) : null
        }
      </Media>
    </LC.Container>
  );
};

LearnCard.defaultProps = {
  imageIsZoomed: false,
  showExplanation: false,
  isInTestResult: false
};
