// @flow

import styled from "styled-components";

import * as React from "react";
import Grid from "styled-components-grid";

import type { AppStateType } from "../redux/reducers";
import type { LearningSetState } from "../redux/reducers/flavour";

import {
  Container,
  GridCenterContainer,
  ModalContainer
} from "../components/container";
import Space from "../components/spacing";
import SetsPicker, {
  StyledSelectContainer,
  StyledSelect
} from "../components/categories/sets_picker";
import {
  LearnTestTabContainer,
  LearnTestTab
} from "../components/dashboard/learn_test_tab";
import type { OverallProgressType } from "../components/dashboard/overall_progress";
import CategoriesPage from "./categories_page";
import CouchingPage from "./coaching";
import TestsPage from "./tests";
import { connect } from "react-redux";
import { setDashboardData } from "../redux/actions/dashboard";
import { setUser } from "../redux/actions/user";
import { Body, Footer } from "../components/text";
import { BigSpinner } from "../components/spinner";
import { callAPI } from "../utils/api";
import DataLoader from "../components/data_loader";
import { Switch, Route } from "react-router-dom";

const FullWidthSection = styled(Container)`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  background-color: ${(props) => props.theme.color.lightGray};
  border-bottom: solid 2px #dedede;
`;
const CategoriesContainer = styled(GridCenterContainer)`
  max-width: 1000px !important;

  @media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    max-width: 100%;
  }
`;
const StyledTitle = styled(Body)`
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: lighter;
`;
const SelectProductOrSetInfo = styled(Footer)`
  margin-bottom: 10px;
  text-align: center;
`;

type Flavour = {
  title: string,
  set: string,
  sets: Array<LearningSetState>,
  type: string,
  isCoachingEnabled: boolean
};

type CategoriesAndTestsPageMessage = {
  learn: string,
  test: string,
  yourProgress: string
};

type CategoriesAndTestsPagePropsType = {
  selectedTab: "learn" | "test",
  selectedSet: LearningSetState,
  onChangeFlavour?: (flavour: string) => void,
  flavour: Flavour,
  overallProgress: OverallProgressType,
  setSelectedTab?: (tab: string) => void,
  changeSet?: (set: LearningSetState) => void,
  messages: CategoriesAndTestsPageMessage,
  selectedLanguage: string,
  isLoading: boolean,
  setIsLoading: (isLoading: boolean) => void,
  announcement?: {
    title: string,
    message: string
  }
};
const AnnouncementContainer = styled.div`
  padding: 20px;
  background-color: #ffff005e;
  text-align: center;
`;
export const CategoriesAndTestsPage = ({
  user,
  selectedSet,
  flavour,
  overallProgress,
  setSelectedTab = () => {},
  changeSet = () => {},
  onChangeFlavour = () => {},
  messages,
  selectedLanguage,
  isLoading,
  match,
  announcement,
  ...rest
}: CategoriesAndTestsPagePropsType) => {
  var tabs = ["learn", "test"];
  if (flavour.isCoachingEnabled) {
    tabs.push("coaching");
  }
  const {
    params: { tab: selectedTab }
  } = match;

  return (
    <React.Fragment>
      <FullWidthSection>
        {announcement && (
          <AnnouncementContainer>
            <h2>{announcement.title}</h2>
            <p>{announcement.message}</p>
          </AnnouncementContainer>
        )}
        <Space v={30} />

        {user.validVouchersFlavours.length === 1 && (
          <StyledTitle>{flavour.title}</StyledTitle>
        )}

        {(user.validVouchersFlavours.length > 1 ||
          (flavour.sets && flavour.sets.length > 1)) && (
          <SelectProductOrSetInfo>
            {messages.selectProductOrSet}
          </SelectProductOrSetInfo>
        )}
        {user.validVouchersFlavours.length > 1 && (
          <Grid halign="center">
            <Grid.Unit size={{ desktop: 1 / 4, mobile: 1, tablet: 1 / 4 }}>
              <StyledSelectContainer>
                <StyledSelect
                  onChange={(e: any) => onChangeFlavour(e.target.value)}
                  value={flavour.name}>
                  {user.validVouchersFlavours.map((f) => {
                    return (
                      <option key={f._id} value={f._id}>
                        {f.title[selectedLanguage]}
                      </option>
                    );
                  })}
                </StyledSelect>
              </StyledSelectContainer>
            </Grid.Unit>
          </Grid>
        )}
        <Space v={10} />
        {flavour.sets && flavour.sets.length > 1 && (
          <Grid halign="center">
            <Grid.Unit size={{ desktop: 1 / 4, mobile: 1, tablet: 1 / 4 }}>
              <SetsPicker
                sets={flavour.sets}
                selectedSet={selectedSet || flavour.sets[0]}
                onChange={(e: any) => changeSet(e.target.value)}
                selectedLanguage={selectedLanguage}
              />
            </Grid.Unit>
          </Grid>
        )}

        <LearnTestTabContainer>
          {tabs.map((tab, i) => (
            <LearnTestTab
              key={i}
              isActive={selectedTab === tab}
              text={messages[tab].toUpperCase()}
              to={`/dashboard/${tab}`}
            />
          ))}
        </LearnTestTabContainer>
      </FullWidthSection>

      <Grid halign="center">
        <CategoriesContainer>
          <Switch>
            <Route
              path="/dashboard/learn"
              render={(props) => (
                <CategoriesPage
                  messages={messages}
                  overallProgress={overallProgress}
                  showTruckPlus={user.showTruckPlus}
                />
              )}
            />
            <Route path="/dashboard/test" component={TestsPage} />
            <Route path="/dashboard/coaching" component={CouchingPage} />
          </Switch>
        </CategoriesContainer>
      </Grid>

      {isLoading && (
        <ModalContainer>
          <BigSpinner />
        </ModalContainer>
      )}
    </React.Fragment>
  );
};

const StatefulCategoriesAndTestsPage = (props) => {
  var initialTab = "learn";
  if (
    props.location &&
    props.location.state &&
    props.location.state.selectedTab
  ) {
    initialTab = props.location.state.selectedTab;
  }
  const [selectedTab, setSelectedTab] = React.useState(initialTab);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedSet, setSelectedSet] = React.useState(
    props.selectedSet || null
  );
  const [announcement, setAnnouncement] = React.useState(null);
  const dispatch = props.dispatch;

  // check if there is announcement
  React.useEffect(() => {
    fetch(process.env.REACT_APP_ANNOUNCEMENT_URL)
      .then((res) => res.json())
      .then((result) => {
        if (
          result.title &&
          result.title[props.selectedLanguage] &&
          result.message &&
          result.message[props.selectedLanguage]
        ) {
          const date = new Date(result.expire);
          const now = Date.now();
          if (date > now) {
            setAnnouncement({
              title: result.title[props.selectedLanguage],
              message: result.message[props.selectedLanguage]
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.location, props.selectedLanguage]);

  const onChangeFlavour = React.useCallback(
    (value) => {
      var shouldShowLoading = true;
      setTimeout(() => {
        if (shouldShowLoading) {
          setIsLoading(true);
        }
      }, 2000);

      callAPI(`/api/active_flavour`, { flavour: value })
        .then(() => callAPI("/dashboard"))
        .then((data) => {
          dispatch(setDashboardData(data));
        })
        .then(() => callAPI("/api/user"))
        .then((data) => {
          shouldShowLoading = false;
          dispatch(setUser(data));
          setIsLoading(false);
        });
    },
    [dispatch]
  );

  const changeSet = React.useCallback(
    (value) => {
      var shouldShowLoading = true;
      setTimeout(() => {
        if (shouldShowLoading) {
          setIsLoading(true);
        }
      }, 2000);

      callAPI(`/api/active_set`, { set: value })
        .then(() => callAPI("/dashboard"))
        .then((data) => {
          shouldShowLoading = false;
          setSelectedSet({ code: value });
          dispatch(setDashboardData(data));

          setIsLoading(false);
        });
    },
    [dispatch]
  );

  return (
    <CategoriesAndTestsPage
      {...{
        ...props,
        announcement,
        selectedTab,
        setSelectedTab,
        isLoading,
        setIsLoading,
        selectedSet,
        setSelectedSet,
        onChangeFlavour,
        changeSet
      }}
    />
  );
};

// get data from server
const DashboardWithData = (props) => {
  if (typeof window !== undefined) {
    window.sessionStorage.removeItem("transactionId");
  }
  return (
    <DataLoader
      pathname="/dashboard"
      onDataFetch={(data) => props.dispatch(setDashboardData(data))}
      component={StatefulCategoriesAndTestsPage}
      {...props}
    />
  );
};
const mapStateToProps = (state: AppStateType) => {
  return {
    messages: state.language.messages,
    user: state.user,
    flavour: {
      ...state.flavour,
      title: state.flavour.title[state.language.selected]
    },
    selectedSet: { code: state.flavour.selectedSet },
    overallProgress: state.overallProgress,
    selectedLanguage: state.language.selected
  };
};

export default connect(mapStateToProps)(DashboardWithData);
