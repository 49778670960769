// @flow

import styled from "styled-components";

export const RoundedImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50px;
`;

export const AppIcon = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  -webkit-box-shadow: -9px 11px 30px -5px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: -9px 11px 30px -5px rgba(0, 0, 0, 0.47);
  box-shadow: -9px 11px 30px -5px rgba(0, 0, 0, 0.47);
`;
