// @flow

import styled from "styled-components";

const Space = styled.div`
  width: ${props => props.h || 0}px;
  height: ${props => props.v || 0}px;
`;

export default Space;
