// @flow
import React, { Fragment } from "react";

import { Title, Body, Footer, Description } from "../components/text";
import { Link, ButtonWithSpinner } from "../components/button";
import Space from "../components/spacing";
import InfoBox from "../components/infobox";
import { AppIcon } from "../components/img";
import styled from "styled-components";
import { Container, RaisedContainer } from "../components/container";
import LanguageWarning from "../components/language-warning";

const ProductContainer = styled.div`
  padding: 4em;
  position: relative;
  width: 40%;

  @media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    width: 60%;
  }

  @media only screen and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    width: inherit;
    padding: inherit;
    animation: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`;

const ProductPromoImage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 400px;

  & > img {
    display: block;
    flex: 1;
    margin-top: auto;
    margin-bottom: auto;
    height: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }

  @media only screen and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    & > img {
      border-radius: 0;
    }
  }
`;

const ProductTitle = styled(Title)`
  font-size: 1.2em;
  text-align: left;
  align-self: flex-start;
  font-weight: normal;
`;

const ProductIcon = styled(AppIcon)`
  align-self: flex-start;
  width: 60px;
  height: 60px;
  box-shadow: none;
`;

const ProductPrice = styled(Body)`
  font-size: 2em;
  margin: 0;
`;

const StyledLink = styled(Link)`
  font-size: 0.8em;
`;

export type BuyPageMessageType = {
  buyVoucherPrice: string,
  pleaseWait: string,
  buyNow: string,
  haveVoucher: string,
  otherProducts: string,
  payOnce: string
};
type BuyPageProps = {
  flavour: {
    name: string,
    icon: string,
    title: string,
    description: string,
    price: string
  },
  info?: string,
  messages: BuyPageMessageType,
  isLoading: boolean,
  onClickBuyButton: () => void,
  onClickBackToLogin: () => void,
  onClickOtherProducts: () => void,
  showOtherProducts: boolean,
  selectedLanguage: string
};
export const BuyPage = (props: BuyPageProps) => {
  const {
    messages = {},
    flavour,
    info,
    isLoading,
    onClickBuyButton,
    onClickBackToLogin,
    onClickOtherProducts,
    showOtherProducts,
    selectedLanguage
  } = props;

  if (!flavour) {
    return null;
  }

  return (
    <div>
      <RaisedContainer>
        <ProductPromoImage>
          <img
            alt={flavour.name}
            src={`https://itheorie.ch/images/${flavour.name}_promo-original.png`}
          />
        </ProductPromoImage>
        <div style={{ padding: "1em" }}>
          <ProductContainer>
            <ProductIcon src={flavour.icon} />
            <ProductTitle>{flavour.title}</ProductTitle>
            {info && !isLoading && (
              <Fragment>
                <InfoBox>{info}</InfoBox>
                <Space v={20} />
              </Fragment>
            )}
            <ProductPrice>{`${flavour.localizedPrice}`}</ProductPrice>
            <Footer>{messages.payOnce}</Footer>
            <Space v={20} />
            <Description>{flavour.description}</Description>
            <Space v={20} />
            {flavour.languages.indexOf(selectedLanguage) === -1 && (
              <LanguageWarning>{messages.unsupportedLanguage}</LanguageWarning>
            )}

            <ButtonWithSpinner
              secondary
              disabled={isLoading}
              onClick={onClickBuyButton}
              showSpinner={isLoading}>
              {isLoading ? `${messages.pleaseWait} ` : messages.buyNow}
            </ButtonWithSpinner>
            {!isLoading && (
              <Fragment>
                <Space v={30} />
                <Container row>
                  <StyledLink href="#" onClick={onClickBackToLogin}>
                    {messages.haveVoucher}
                  </StyledLink>
                  {showOtherProducts && (
                    <Fragment>
                      <Space h={20} />
                      <StyledLink href="#" onClick={onClickOtherProducts}>
                        {messages.otherProducts}
                      </StyledLink>
                    </Fragment>
                  )}
                </Container>
              </Fragment>
            )}
          </ProductContainer>
        </div>
      </RaisedContainer>
    </div>
  );
};

BuyPage.defaultProps = {
  isLoading: false,
  onClickBuyButton: () => {}
};
