// @flow

export type LearningSetState = {
  code: string,
  names: Object
};

export type FlavourState = {
  icon: string,
  title: Object,
  description: Object,
  price: {
    currency: string,
    value: number
  },
  sets: Array<LearningSetState>,
  languages: Array<string>,
  defaultLanguage: string,
  selectedSet: string,
  country: string,
  name: string,
  appid: string,
  gtag_conversion: Object,
  isCoachingEnabled: boolean,
  payrex: string
};
export type SetFlavourAction = {
  type: string,
  payload: FlavourState
};

export default (
  state: FlavourState = {},
  action: SetFlavourAction
): FlavourState => {
  switch (action.type) {
    case "SET_FLAVOUR": {
      return {
        ...state,
        ...action.payload,
        defaultLanguage: action.payload["default_language"] || "en"
      };
    }
    default:
  }
  return state;
};

export const flavourWithLanguage = (
  flavour: FlavourState,
  language: string
) => ({
  ...flavour,
  title: flavour.title[language],
  description: flavour.description[language],
  localizedPrice: new Intl.NumberFormat("de-CH", {
    style: "currency",
    currency: "CHF"
  }).format(flavour.price.value)
});
