import * as React from "react";
import { Container } from "../components/container";
import { stringWithFormat } from "../utils/locale";
import { connect } from "react-redux";

export const TeacherPendingPage = ({ teacher, messages }) => {
  return (
    <Container>
      <br />
      <p>
        {stringWithFormat(messages.connectRequested, {
          $teacher: teacher
        })}
      </p>
    </Container>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  messages: state.language.messages
});

export default connect(mapStateToProps)(TeacherPendingPage);
