// @flow

import type { SetUserActionType } from "./user";
import { supportedLanguages } from "../../utils/locale";

export type SupportedLanguage = "en" | "de" | "it" | "fr";
export type LanguageState = {
  selected: SupportedLanguage,
  messages: Object,
  supportedLanguages: Array<SupportedLanguage>
};
export type SetLanguageAction = {
  type: "SET_LANGUAGE_ACTION",
  payload: {
    selected: SupportedLanguage,
    messages: Object
  }
};

export const actionForSetLanguage = (
  selected: SupportedLanguage,
  messages: Object
): SetLanguageAction => ({
  type: "SET_LANGUAGE_ACTION",
  payload: {
    selected,
    messages
  }
});

export const defaultState = {
  selected: "en",
  messages: require("../../locales/en").default,
  supportedLanguages: supportedLanguages.map((l) => l.id)
};

export default (
  state: LanguageState = defaultState,
  action: SetLanguageAction | SetUserActionType
): LanguageState => {
  switch (action.type) {
    case "SET_LANGUAGE_ACTION": {
      return {
        ...state,
        ...action.payload
      };
    }
    case "SET_FLAVOUR": {
      // set the supported languages based on current flavour
      return {
        ...state,
        supportedLanguages:
          action.payload.languages || defaultState.supportedLanguages
      };
    }
    case "SET_PRODUCTS": {
      // show all languages when in products page
      return {
        ...state,
        supportedLanguages: defaultState.supportedLanguages
      };
    }
    default:
  }
  return state;
};
