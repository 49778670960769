import * as React from "react";
import { Container } from "../components/container";
import { stringWithFormat } from "../utils/locale";
import { connect } from "react-redux";

export const TeacherInactivePage = ({ teacher, messages }) => {
  return (
    <Container>
      {stringWithFormat(messages.studentDeactivated, {
        $teacher: teacher
      })}
    </Container>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  messages: state.language.messages
});

export default connect(mapStateToProps)(TeacherInactivePage);
