// @flow

import type { SupportedLanguage } from "../reducers/language";
import { actionForSetLanguage } from "../reducers/language";
import { callAPI } from "../../utils/api";
import { supportedLanguages } from "../../utils/locale";
import type { AppStateType } from "../reducers";
import moment from "moment";

import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/it";

const locales = {
  en: import("../../locales/en"),
  de: import("../../locales/de"),
  fr: import("../../locales/fr"),
  it: import("../../locales/it")
};

export const setLanguage = (language: SupportedLanguage = "en") => (
  dispatch: any,
  getState: () => AppStateType
) => {
  if (
    supportedLanguages.map(l => l.id).indexOf(language) > -1 &&
    getState().language.selected !== language
  ) {
    return callAPI(`/language`, {
      language
    })
      .then(() => {
        moment.locale(language);
        return locales[language];
      })
      .then(messages => {
        dispatch(actionForSetLanguage(language, messages.default));
      });
  }
};
