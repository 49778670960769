// @flow

import * as React from "react";
import { connect } from "react-redux";

import type { AppStateType } from "../redux/reducers";
import type { UserStateType } from "../redux/reducers/user";
import { Redirect, Route } from "react-router-dom";

/**
 *  Component will be shown only to authenticated user.
 *  If not authenticated, user will be redirected to /
 */
export const PrivateRoute = ({
  user,
  component: Component,
  redirectTo,
  ...rest
}: {
  user: UserStateType,
  children: any
}) => (
  <Route
    {...rest}
    render={props => {
      if (user && user.authenticated) {
        if (redirectTo) {
          return <Redirect to={redirectTo} />;
        }
        return <Component {...props} />;
      }
      return <Redirect to={"/"} />;
    }}
  />
);

const mapStateToProps = (state: AppStateType) => ({
  user: state.user
});
export default connect(mapStateToProps)(PrivateRoute);

/**
 *  This Component props will only be shown to un-authenticated user.
 *  If user is authenticated, they will be redirected to /dashboard immediately.
 */
const OnlyPublic = ({ user, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (user && user.authenticated) {
        return <Redirect to={"/dashboard"} />;
      }
      return <Component {...props} />;
    }}
  />
);
export const OnlyPublicRoute = connect(mapStateToProps)(OnlyPublic);

/**
 *  This Route component redirects the user to /dashboard if they are authenticated.
 *  If not authenticated, redirect to the path specified in `to` props.
 */
const OnlyPublicRedirect = ({ user, to, ...rest }) => (
  <Route
    {...rest}
    render={() => {
      if (user && user.authenticated) {
        return <Redirect to={"/dashboard"} />;
      }
      return <Redirect to={to} />;
    }}
  />
);
export const OnlyPublicRedirectRoute = connect(mapStateToProps)(
  OnlyPublicRedirect
);
