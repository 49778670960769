// @flow

export type ThemePropsType = {
  color: {
    primary: string,
    secondary: string,
    tertiary: string,
    failed: string,
    text: string,
    textLight: string,
    disabled: string,
    error: string,
    warning: string,
    info: string,
    dark: string,
    primaryDark: string,
    primaryLight: string,
    red: string,
    green: string,
    darkGreen: string,
    orange: string,
    gray: string,
    lightPrimary: string
  },
  font: {
    family: string,
    title: {
      size: string,
      weight: string
    },
    heading: {
      size: string,
      weight: string
    },
    subtitle: {
      size: string,
      weight: string
    },
    body: {
      size: string,
      weight: string
    },
    footer: {
      size: string,
      weight: string
    }
  }
};
const theme: ThemePropsType = {
  name: "light",
  color: {
    background: "#ececec",
    raisedBackground: "white",
    primary: "#03A9F4",
    secondary: "#2ECC71",
    tertiary: "#033e6d",
    failed: "#EB6456",
    text: "#1C2833",
    disabled: "#e0e0e0",
    error: "#e91e63",
    warning: "#f1e05a",
    info: "#c8e1ff",
    dark: "#303840",
    textLight: "#a7a7a7",
    primaryDark: "#0389c5",
    primaryLight: "#f3fbff",
    red: "red",
    green: "green",
    darkGreen: "darkGreen",
    orange: "orange",
    gray: "gray",
    lightGray: "#f7f7f7",
    activeTab: "white",
    activeTabBorderTop: "#03A9F4",
    categoryBox: "white",
    learnCardExplanation: "#d4d4d4",
    lightPrimary: "#dff1ff"
  },
  font: {
    family:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    title: {
      size: "40px",
      weight: "lighter"
    },
    heading: {
      size: "25px",
      weight: "bolder"
    },
    subtitle: {
      size: "21px",
      weight: "300"
    },
    body: {
      size: "19px",
      weight: "normal"
    },
    footer: {
      size: "16px",
      weight: "normal"
    }
  }
};

export const darkTheme: ThemePropsType = {
  name: "dark",
  color: {
    background: "#080a15",
    raisedBackground: "#2c353c",
    primary: "#002d42",
    secondary: "#2ECC71",
    tertiary: "#033e6d",
    failed: "#EB6456",
    text: "white",
    disabled: "#444444",
    error: "#e91e63",
    warning: "#f1e05a",
    info: "#c8e1ff",
    dark: "#303840",
    textLight: "#a7a7a7",
    primaryDark: "#002d42",
    primaryLight: "#3f4244",
    red: "red",
    green: "green",
    darkGreen: "darkGreen",
    orange: "orange",
    gray: "gray",
    lightGray: "#001b29",
    activeTab: "#00385a",
    activeTabBorderTop: "white",
    categoryBox: "#001a2a",
    learnCardExplanation: "#404040",
    lightPrimary: "#275273"
  },
  font: {
    family:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    title: {
      size: "40px",
      weight: "lighter"
    },
    heading: {
      size: "25px",
      weight: "bolder"
    },
    subtitle: {
      size: "21px",
      weight: "300"
    },
    body: {
      size: "19px",
      weight: "normal"
    },
    footer: {
      size: "12px",
      weight: "normal"
    }
  }
};

export const withOpacity = (hex: string, opacity: number) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
  if (!rgb) {
    return hex;
  }
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
};

export default theme;
