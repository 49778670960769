// @flow

import * as React from "react";
import { BigSpinner } from "../components/spinner";
import { Redirect, withRouter } from "react-router-dom";
import { callAPI } from "../utils/api";
import { connect } from "react-redux";
import type { AppStateType } from "../redux/reducers";
import { ModalContainer } from "../components/container";

export class LoginWithLinkPage extends React.Component {
  state = {
    isLoading: true,
    errorMessage: "",
    flavour: ""
  };
  componentDidMount() {
    callAPI(this.props.location.pathname)
      .then(result => {
        if (result.authenticated) {
          callAPI(`/flavour/${result.flavour}`).then(flavourResult => {
            window.location = "/dashboard";
          });
        } else {
          this.setState({
            isLoading: false,
            errorMessage: result.message,
            flavour: result.flavour
          });
        }
      })
      .catch(e => console.log(e));
  }
  render() {
    if (this.state.isLoading) {
      return (
        <ModalContainer>
          <BigSpinner />
        </ModalContainer>
      );
    }
    return (
      <Redirect
        to={{
          pathname: `/flavour/${this.state.flavour}`,
          state: {
            loginMessage: [this.state.errorMessage]
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppStateType) => ({
  messages: state.language.messages
});

export default withRouter(connect(mapStateToProps)(LoginWithLinkPage));
