// @flow

import React from "react";
import type { ComponentType } from "react";
import styled from "styled-components";
import type { ThemePropsType } from "../theme";
import { Link } from "react-router-dom";

export const LearnTestTabContainer = styled.ul`
  display: flex;
  list-style-type: none;
  border-bottom: solid;
  border-bottom-color: ${props => props.theme.color.background};
  border-bottom-width: 2px;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  margin-bottom: 0;
`;

type StyledLiProps = {
  isActive?: boolean,
  theme?: ThemePropsType
};
const StyledLi: ComponentType<StyledLiProps> = styled.li`
  display: block;
  min-width: 60px;
  float: left;
  text-align: center;
  background-color: ${(props: StyledLiProps) =>
    props.isActive ? props.theme.color.background : "none"};
  padding: 10px 20px;
  margin: 0 5px -4px 5px;
  font-family: ${props => props.theme.font.family};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-style: ${props => (props.isActive ? "solid" : "none")};
  border-width: 2px;
  border-color: ${props =>
    props.isActive ? "#dedede" : props.theme.color.primary};
  border-top-color: ${props =>
    props.isActive ? props.theme.color.activeTabBorderTop : "none"};
  border-top-width: 4px;
  border-bottom-color: ${(props: StyledLiProps) =>
    props.isActive
      ? props.theme.color.background
      : props.theme
      ? props.theme.color.primary
      : "black"};

  a {
    color: ${props => (props.isActive ? props.theme.color.text : "#717171")};
    text-decoration: none;
  }
`;

type LearnTestTabPropsType = {
  text: string,
  to?: string,
  onClick?: (e: Event) => void,
  isActive?: boolean
};
export const LearnTestTab = ({ text, to, isActive }: LearnTestTabPropsType) => (
  <StyledLi isActive={isActive}>
    <Link to={to}>{text}</Link>
  </StyledLi>
);
