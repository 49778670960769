// @flow

import React from "react";
import styled from "styled-components";
import Tooltip from "@tippy.js/react";
import { Title, Body } from "../text";
import { connect } from "react-redux";
import "tippy.js/themes/light.css";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 40px;
  background-color: ${props => props.theme.color.lightGray};
  border: solid 1px #dedede;
  align-items: center;
  justify-content: space-around;
`;
const Total = styled(Title)`
  color: ${props => props.theme.color.green};
  font-size: 5em;
  margin: 0;

  @media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    font-size: 2.5em;
  }
`;
const EmptyProgress = styled(Body)`
  text-align: center;
  flex: 1;
  color: ${props => props.theme.color.text};
  padding-left: 40px;
`;

const Progress = styled.div`
  display: block;
  border-radius: 50%;
  background-color: ${props => props.color || "gray"};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  width: 90px;
  height: 90px;

  & > a {
    display: block;
    flex: 1;

    text-decoration: none;
    cursor: ${props => (props.to !== "#" ? "pointer" : "not-allowed")};
    text-align: center;
    color: white;
    font-size: 30px;

    @media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      font-size: 17px;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    width: 45px;
    height: 45px;
  }
`;

const ProgressTooltip = styled(Tooltip).attrs({
  placement: "top",
  arrow: true,
  animation: "scale",
  size: "large",
  className: "progress-tooltip",
  theme: "light"
})``;
const ProgressTooltipContent = styled(Body)`
  color: ${props => props.progress};
  text-align: center;
  padding: 20px;
`;

type OverallProgressMessageType = {
  emptyProgress: string
};
export type ProgressType = {
  count: number,
  color: string,
  url: string,
  description: string
};
export type OverallProgressType = {
  total: number,
  percentage: number,
  progresses: Array<ProgressType>,
  className?: string,
  messages?: OverallProgressMessageType
};

export const OverallProgress = ({
  total,
  percentage,
  progresses,
  className,
  messages
}: OverallProgressType) => {
  const isEmpty = total === 0;
  return (
    <Container className={className}>
      <Total>{`${percentage || 0}%`}</Total>
      {isEmpty && (
        <EmptyProgress>{messages && messages.emptyProgress}</EmptyProgress>
      )}
      {!isEmpty &&
        progresses.map((p, i) => (
          <ProgressTooltip
            key={i}
            content={
              <ProgressTooltipContent progress={p.color}>
                {p.description.replace("$progress", p.count)}
              </ProgressTooltipContent>
            }
          >
            <Progress color={p.color} to={p.count === 0 ? "#" : p.url}>
              <Link to={p.count === 0 ? "#" : p.url}>{p.count}</Link>
            </Progress>
          </ProgressTooltip>
        ))}
    </Container>
  );
};

OverallProgress.defaultProps = {
  total: 0,
  progresses: ["red", "darkGreen", "green", "orange", "gray"].map(c => ({
    color: c,
    count: 0,
    url: `/learn/overall-${c}`
  }))
};

const mapStateToProps = state => ({
  messages: state.language.messages,
  ...{
    ...state.overallProgress,
    progresses: state.overallProgress.progresses.map(p => ({
      ...p,
      description: state.language.messages[p.description]
    }))
  }
});
export default connect(mapStateToProps)(OverallProgress);
