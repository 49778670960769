//@flow

import * as React from "react";
import { Provider, connect } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import LoginPage from "./pages/login";
import BuyPage from "./pages/buy.container";
import store from "./redux/store";
import ProductsPage from "./pages/products";
import Header from "./components/header";
import CategoriesAndTestsPage from "./pages/dashboard";
import LearnPage from "./pages/learn";
import PaymentCompletedPage from "./pages/payment_completed";
import TestPage from "./pages/test_page";
import TestResultPage from "./pages/test_result";
import SearchPage from "./pages/search";
import PrivateRoute, {
  OnlyPublicRoute,
  OnlyPublicRedirectRoute
} from "./components/private_route";
import { callAPI } from "./utils/api";
import { setUser } from "./redux/actions/user";
import { setFlavour } from "./redux/actions/flavour";
import LoginWithLinkPage from "./pages/login_link";

import ReactGA from "react-ga";
import ActivityReporter from "./components/activity_reporter";
import { supportedLanguages } from "./utils/locale";
import { setLanguage } from "./redux/actions/language";
import Helmet from "./components/helmet";
import Footer from "./components/footer";
import QRCode from "./pages/qrcode";
import { setProducts } from "./redux/actions/products";

if (process.env.REACT_APP_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID, {
    debug: process.env.NODE_ENV === "development"
  });
  ReactGA.plugin.require("ec");
}

if (process.env.REACT_APP_GOOGLE_OPTIMIZE_ID) {
  ReactGA.ga("require", process.env.REACT_APP_GOOGLE_OPTIMIZE_ID);
}

// this component does not render anything but when the route changes it calls google analytics
const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
  return null;
};

// this component does not render anyting
// but when there's lang={lang_code} in the url,
// it will set the language in redux if needed
const setLanguageFromQuery = ({
  location,
  dispatch
}: {
  location: Object,
  dispatch: any
}) => {
  if (location.search) {
    var langQuery = location.search
      .substr(1)
      .split("&")
      .filter(q => q.indexOf("lang=") > -1);
    if (langQuery.length > 0) {
      langQuery = langQuery[0].split("=");
      if (langQuery.length === 2 && langQuery[1].length > 0) {
        const lang = langQuery[1];
        if (supportedLanguages.filter(s => s.id === lang).length > 0) {
          dispatch(setLanguage(lang));
        }
      }
    }
  }
  return null;
};
const connectedSetLanguageFromQuery = connect()(setLanguageFromQuery);

// keep the footer at the bottom when the content is short
const Content = styled.div`
  min-height: calc(100vh - 110px);
`;

const rootRoute = flavours => {
  if (!flavours || (flavours && flavours.length > 1)) {
    return (
      <OnlyPublicRoute
        exact
        path="/"
        component={props => <ProductsPage {...props} goToLogin={true} />}
      />
    );
  } else if (flavours && flavours.length === 1) {
    // in elearning.itheorie.de, there's only 1 flavour, so redirect immediately
    return (
      <OnlyPublicRedirectRoute
        exact
        path="/"
        to={`/flavour/${flavours[0].name}`}
      />
    );
  }
};

const RoutesContainer = styled.div`
  overflow-x: hidden;
  background-color: ${props => props.theme.color.background};
  color: ${props => props.theme.color.text};
`;

const Routes = ({ flavours }) => (
  <RoutesContainer style={{ overflowX: "hidden" }}>
    <Header />
    <Content>
      <Route component={logPageView} />
      <Route component={connectedSetLanguageFromQuery} />
      <Switch>
        {rootRoute(flavours)}
        <OnlyPublicRoute exact path="/coaching/q/:jwt" component={LearnPage} />
        <OnlyPublicRoute
          exact
          path="/payment-completed"
          component={PaymentCompletedPage}
        />
        <OnlyPublicRoute
          exact
          path="/coaching/test/:jwt"
          component={TestResultPage}
        />
        <OnlyPublicRoute exact path="/coaching/:jwt" component={SearchPage} />
        <OnlyPublicRoute exact path="/login" component={LoginPage} />
        <OnlyPublicRoute exact path="/flavour/:flavour" component={LoginPage} />
        <OnlyPublicRoute exact path="/scan" component={QRCode} />
        <OnlyPublicRoute path="/flavour/:flavour/scan" component={QRCode} />
        <OnlyPublicRoute
          path="/login/:forgotVerifyCode"
          component={LoginWithLinkPage}
        />
        <Route path="/flavour/:flavour/buy" component={BuyPage} />
        <Route path="/products" component={ProductsPage} />

        <PrivateRoute
          exact={true}
          path="/dashboard"
          redirectTo="/dashboard/learn"
        />
        <PrivateRoute
          path="/dashboard/:tab"
          component={CategoriesAndTestsPage}
        />
        <PrivateRoute exact path="/learn/test" component={TestPage} />
        <PrivateRoute
          path="/learn/test/:testId/result"
          component={TestResultPage}
        />
        <PrivateRoute
          path="/learn/:category/:subcategory?"
          component={LearnPage}
        />
        <PrivateRoute path="/search" component={SearchPage} />
        <PrivateRoute path="/question/:code" component={LearnPage} />
        <Redirect to="/" />
      </Switch>
    </Content>
    <Footer />
  </RoutesContainer>
);

class ThemedApp extends React.Component {
  render() {
    return (
      <ThemeProvider theme={this.props.theme}>
        <Router>
          <Routes flavours={this.props.flavours} />
        </Router>
      </ThemeProvider>
    );
  }
}

const ConnectedThemedApp = connect(state => ({ theme: state.theme }))(
  ThemedApp
);

class App extends React.Component<{}, { initialFetch: boolean }> {
  constructor() {
    super();

    this.state = {
      initialFetch: false,
      flavours: null
    };
  }

  componentDidMount() {
    // check theme from URL query string
    const query = window.location.search.substring(1);
    if (query) {
      const themeQuery = query.split("&").filter(q => q.startsWith("theme="));
      if (themeQuery && themeQuery.length > 0) {
        const themeQueryComps = themeQuery[0].split("=");
        if (themeQueryComps && themeQueryComps.length === 2) {
          const requestedTheme = themeQueryComps[1];
          store.dispatch({
            type: "SET_THEME",
            payload:
              requestedTheme === "dark"
                ? require("./components/theme").darkTheme
                : require("./components/theme").default
          });
        }
      }
    }

    // when this root component mounted, we check if user has been authenticated in the browser
    // by fetching the user from the API.
    // if authenticated, we set the user in redux

    callAPI(`/api/user`)
      .then(result => {
        if (result && result.authenticated) {
          callAPI(`/flavour/${result.flavour}`).then(flavourResult => {
            store.dispatch(setFlavour(flavourResult));
            store.dispatch(setUser(result));
            this.setState({ initialFetch: true });
          });
        } else {
          // so user is not authenticated, let's fetch the products
          // so that we can route them to the login page if there's only 1 product (in elearning.itheorie.de)
          // or show welcome screen to choose products (elearning.itheorie.ch)
          callAPI("/api/products").then(flavours => {
            if (flavours && flavours.length === 1) {
              store.dispatch(setLanguage(flavours[0].default_language));
            } else {
              store.dispatch(setProducts(flavours));
            }
            this.setState({ initialFetch: true, flavours });

            return;
          });
        }
      })
      .catch(err => this.setState({ initialFetch: true }));
  }

  render() {
    if (!this.state.initialFetch) {
      return null;
    }
    return (
      <Provider store={store}>
        <React.Fragment>
          <Helmet />
          <ActivityReporter />
          <ConnectedThemedApp flavours={this.state.flavours} />
        </React.Fragment>
      </Provider>
    );
  }
}

export default App;
