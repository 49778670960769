//@flow

import * as React from "react";
import styled from "styled-components";
import Button from "../components/button";
import SectionTitle from "../components/section_title";
import { Body } from "../components/text";
import { BigSpinner } from "../components/spinner";
import { Row, FullWidthRaisedContainer } from "../components/container";
import Space from "../components/spacing";
import {
  compose,
  withState,
  withHandlers,
  lifecycle,
  onlyUpdateForKeys,
} from "recompose";
import { callAPI } from "../utils/api";
import type { AppStateType } from "../redux/reducers";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { stringWithFormat } from "../utils/locale";

const Container = styled.div`
  font-family: ${(props) => props.theme.font.family};
  width: 100%;
  max-width: 1000px;
  margin: 20px auto 0 auto;
`;
const Thumbnail = styled.img`
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 10px;
  margin: 10px 10px 10px 0;
  object-fit: cover;
`;
const SearchContainer = styled.form`
  border: solid 1px #dadada;
  border-radius: 8px;
  min-height: 50px;
  min-width: 200px;
  display: flex;
  flex-direction: row;

  & > input[type="text"] {
    border: none;
    flex: 1;
    font-family: ${(props) => props.theme.font.family};
    font-size: ${(props) => props.theme.font.body.size};
    background: ${(props) => props.theme.color.background};
    color: ${(props) => props.theme.color.text}
    padding: 0 20px;
  }
`;
const SearchResultContainer = styled.ul`
  list-style: none;
  padding: 0;
  flex: 0.5;
`;
const SearchResultRow = styled.li`
  border-bottom: solid 1px #dadada;

  & > a > div > p:first-child {
    font-weight: bold;
  }
  & > a {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: inherit;
  }
  :hover {
    background-color: ${(props) => props.theme.color.primaryLight};
  }
  cursor: pointer;
`;

type SearchPageMessageType = {
  search: string,
  submit: string,
  noResult: string,
  result: string,
  searchPlaceholder: string,
};
type SearchResultType = {
  title: string,
  code: string,
  question: Object,
  url: string,
  image: string,
  type: string,
};
type SearchPagePropsType = {
  keyword: string,
  result?: Array<SearchResultType>,
  isSearching: boolean,
  messages: SearchPageMessageType,
  onClickSearch: () => void,
  setKeyword: (keyword: string) => void,
  setIsSearching: (isSearching: boolean) => void,
  setResult: (result: Array<SearchResultType>) => void,
  location: { pathname: string, search: string },
  history: any,
  selectedLanguage: string,
  hideSearchInput: boolean,
  title: string,
};
export const SearchPage = (props: SearchPagePropsType) => {
  var titleToUse = props.messages.result;
  if (props.title) {
    titleToUse = stringWithFormat(props.messages[props.title], {
      $progress: props.result.length,
    });
  }
  return (
    <Container>
      <FullWidthRaisedContainer>
        {!props.hideSearchInput && (
          <SearchContainer
            onSubmit={(e) => {
              e.preventDefault();
              props.onClickSearch();
            }}
          >
            <input
              disabled={props.isSearching}
              type="text"
              id="search"
              value={props.keyword}
              placeholder={props.messages.searchPlaceholder}
              onChange={(e) => props.setKeyword(e.target.value)}
            />
            <Button
              disabled={props.isSearching || props.keyword.length === 0}
              onClick={(e) => {
                e.preventDefault();
                props.onClickSearch();
              }}
            >
              {props.messages.search}
            </Button>
          </SearchContainer>
        )}
        {props.isSearching && (
          <React.Fragment>
            <Space v={20} />
            <Row center>
              <BigSpinner />
            </Row>
          </React.Fragment>
        )}
        {props.result && <SectionTitle title={titleToUse} />}
        {props.result && props.result.length === 0 && (
          <Body align="center">{props.messages.noResult}</Body>
        )}
        {props.result && props.result.length > 0 && (
          <SearchResultContainer>
            {props.result.map((res, i) => (
              <SearchResultRow key={i}>
                <Link
                  to={{
                    pathname: res.url,
                    state: {
                      fromSearch: `${props.location.pathname}${props.location.search}`,
                    },
                  }}
                >
                  <Thumbnail
                    src={
                      res.typecode === "V"
                        ? require("../components/video.svg")
                        : res.image || require("../components/no_image.svg")
                    }
                  />
                  <div>
                    <p>{res.title}</p>
                    <p>{res.question[props.selectedLanguage]}</p>
                  </div>
                </Link>
              </SearchResultRow>
            ))}
          </SearchResultContainer>
        )}
      </FullWidthRaisedContainer>
    </Container>
  );
};

SearchPage.defaultProps = {
  keyword: "",
  result: null,
  messages: {},
};

const WithState = compose(
  withState("isSearching", "setIsSearching", false),
  withState("hideSearchInput", "setHideSearchInput", false),
  withState("title", "setTitle", null),
  withState("result", "setResult", null),
  withState("keyword", "setKeyword", ""),
  onlyUpdateForKeys(["keyword", "result", "messages", "location"]),
  lifecycle({
    componentDidUpdate(prevProps: SearchPagePropsType) {
      if (prevProps.location.search !== this.props.location.search) {
        if (this.props.keyword.trim().length > 0) {
          this.props.setIsSearching(true);
          callAPI(
            `/question/search?q=${encodeURIComponent(this.props.keyword)}`
          ).then((result) => {
            this.props.setIsSearching(false);
            this.props.setResult(result);
          });
        }
      }
    },
    componentDidMount() {
      if (this.props.location.search) {
        const queries = this.props.location.search
          .substring(1)
          .split("&")
          .map((query) => query.split("="))
          .filter((query) => query.length === 2)
          .filter((query) => query[0] === "q");
        if (queries.length > 0) {
          const q = queries[queries.length - 1][1];
          if (q.trim().length > 0) {
            this.props.setKeyword(decodeURIComponent(q));
            this.props.setIsSearching(true);
            callAPI(`/question/search?q=${q}`).then((result) => {
              this.props.setIsSearching(false);
              this.props.setResult(result);
            });
          }
        }
      }

      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.jwt
      ) {
        this.props.setHideSearchInput(true);
        this.props.setIsSearching(true);
        callAPI(this.props.match.url).then((response) => {
          this.props.setIsSearching(false);
          if (response.result.status) {
            this.props.setTitle(response.result.data.title);
            this.props.setResult(response.result.data.questions);
          }
        });
      }
    },
  }),
  withHandlers({
    onClickSearch: (props: SearchPagePropsType) => () => {
      if (props.keyword.trim().length > 0) {
        props.history.push(`/search?q=${encodeURIComponent(props.keyword)}`);
      }
    },
  })
)(SearchPage);

const mapStateToProps = (state: AppStateType) => ({
  messages: state.language.messages,
  selectedLanguage: state.language.selected,
});

const WithRouter = withRouter(connect(mapStateToProps)(WithState));

export default WithRouter;
