// @flow

import * as React from "react";
import { BuyPage } from "./buy";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import type { AppStateType } from "../redux/reducers";
import { setFlavour } from "../redux/actions/flavour";
import {
  flavourWithLanguage,
  type FlavourState
} from "../redux/reducers/flavour";
import { callAPI } from "../utils/api";
import ProcessingPaymentPage from "./processing_payment";
import { ModalContainer } from "../components/container";

const mapStateToProps = (state: AppStateType): any => ({
  showOtherProducts: state.products.length > 1,
  messages: state.language.messages,
  selectedLanguage: state.language.selected,
  user: state.user,
  flavour:
    state.flavour && state.flavour.name
      ? flavourWithLanguage(state.flavour, state.language.selected)
      : null
});
const ConnectedBuyPage = connect(mapStateToProps)(BuyPage);

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
  max-width: 800px;
  min-height: 800px;
  max-height: 800px;
  background-color: white;

  @media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    width: calc(100vw - 20px);
    height: calc(80vh - 40px);
    min-height: 0;
  }
`;

// get data from path based on current url
class BuyPageWithData extends React.Component<
  {
    messages: any,
    dispatch: any,
    history: any,
    location: { pathname: string },
    selectedLanguage: string,
    flavour: FlavourState,
    match: any
  },
  {
    isProcessingPayment: boolean,
    isLoading: boolean,
    paymentLink: ?string
  }
> {
  state = {
    isLoading: false
  };
  ReactGA = null;
  analyticsPayload = null;
  iframeRef = null;

  constructor(props) {
    super(props);

    this.ReactGA = require("react-ga");
    this.ReactGA.plugin.require("ec");
    this.iframeRef = React.createRef();
  }

  //https://swift.payrexx.com/en/pay?result=1&appview=0&tid=b3aedd54&DATA=8GC03vz2hAlR1Pwc7kBhez%2FNt3C174v%2FhS%2FW3zUq4xgVW%2FW45NK%2FX8giCMvLmFjLGzVEZpQq3DderHkk%2F3so8w%3D%3D

  onClickButton() {
    window.scrollTo(0, 0);
    document.body.style.overflowY = "hidden";
    // GA
    this.ReactGA.plugin.execute("ec", "setAction", "checkout");
    let paymentLink = `https://swift.payrexx.com/${this.props.selectedLanguage}/pay?tid=${this.props.flavour.payrexx}`;
    if (this.props.user && this.props.user.email) {
      paymentLink = `${paymentLink}&contact_email=${encodeURIComponent(
        this.props.user.email
      )}`;
    }

    window.open(paymentLink, "_blank").focus();
  }

  componentDidMount() {
    callAPI(this.props.location.pathname.replace("/buy", "")).then(result => {
      this.props.dispatch(setFlavour(result));

      const payload = {
        id: result.name,
        name: result.title[this.props.selectedLanguage],
        price: result.price.value,
        quantity: 1,
        currency: result.price.currency
      };
      this.analyticsPayload = payload;

      // GA
      this.ReactGA.plugin.execute("ec", "addProduct", this.analyticsPayload);
      this.ReactGA.plugin.execute("ec", "setAction", "detail");
    });
  }

  render() {
    if (!this.props.flavour) {
      return null;
    }
    if (this.state.isProcessingPayment) {
      return <ProcessingPaymentPage messages={this.props.messages} />;
    }
    return (
      <>
        <ConnectedBuyPage
          {...this.props}
          onClickBuyButton={this.onClickButton.bind(this)}
          isLoading={this.state.isLoading}
          onClickBackToLogin={e => {
            e.preventDefault();
            this.props.history.push(
              `/flavour/${this.props.match.params.flavour}`
            );
          }}
          onClickOtherProducts={e => {
            e.preventDefault();
            this.props.history.push("/products");
          }}
        />
        {this.state.paymentLink && (
          <ModalContainer
            backgroundColor="rgba(0, 0, 0, 0.6)"
            onClick={() => {
              document.body.style.overflowY = "scroll";
              this.setState({ paymentLink: "" });
            }}>
            <StyledIFrame
              src={`${this.state.paymentLink}&appview=1`}
              id="myId"
              display="initial"
              position="relative"
              ref={this.iframeRef}
              onLoad={() => {
                if (!window.location.origin) {
                  window.location.origin =
                    window.location.protocol +
                    "//" +
                    window.location.hostname +
                    (window.location.port ? ":" + window.location.port : "");
                }
                this.iframeRef.current.contentWindow.postMessage(
                  JSON.stringify({
                    origin: window.location.origin,
                    integrationMode: "modal"
                  }),
                  `${this.state.paymentLink}&appview=1`
                );
              }}
            />
          </ModalContainer>
        )}
      </>
    );
  }
}

// connect the router to get history and location props
export default withRouter(connect(mapStateToProps)(BuyPageWithData));
