// @flow

import type { UserStateType } from "../reducers/user";
import { callAPI } from "../../utils/api";
import { initialState } from "../reducers/user";
import { setLanguage } from "./language";

export const setUser = (user: UserStateType) => (dispatch: any) => {
  dispatch({
    type: "SET_USER",
    payload: user
  });
  dispatch(setLanguage(user.language));
};

export const login = (email: string, code: string) => (dispatch: any) => {
  callAPI(`/login`, { email, code }, "POST")
    .then(result => dispatch(setUser(result)))
    .catch(() => {
      dispatch(setUser(initialState));
    });
};

export const logout = () => (dispatch: any) => {
  callAPI(`/logout`).then(() => {
    dispatch(setUser(initialState));
  });
};
