// @flow

import type { ProgressType } from "../../components/dashboard/overall_progress";

export type OverallProgressState = {
  total: number,
  progresses: Array<ProgressType>,
  percentage: number
};

export default (
  state: OverallProgressState = {
    total: 0,
    progresses: [],
    percentage: 0
  },
  action: any
): OverallProgressState => {
  switch (action.type) {
    case "SET_OVERALL_PROGRESS": {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
  }
  return state;
};
