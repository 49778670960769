// @flow

import React from "react";
import styled from "styled-components";
import { Circle } from "rc-progress";
import { Body } from "../text";

const TotalProgressCircle = styled(Circle).attrs({
  strokeColor: props => props.theme.color.secondary,
  trailColor: props => props.theme.color.disabled,
  strokeWidth: 10,
  trailWidth: 10,
  className: "progress-circle"
})`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.progress-circle > .rc-progress-circle-trail {
    fill-opacity: 0.5;
    fill: #404040;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Percent = styled(Body)`
  color: white;
  font-size: 30px;
  padding: 30px 30px;
  z-index: 2;
`;

type CategoryTotalProgressPropsType = {
  percent: number
};
const CategoryTotalProgress = ({ percent }: CategoryTotalProgressPropsType) => (
  <Container>
    <Percent>{`${percent}%`}</Percent>
    <TotalProgressCircle percent={percent} />
  </Container>
);

export default CategoryTotalProgress;
