// @flow

import * as React from "react";
import styled from "styled-components";
import { Container } from "../components/container";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { callAPI } from "../utils/api";
import { type AppStateType } from "../redux/reducers";
import { type SupportedLanguage } from "../redux/reducers/language";
import Button from "../components/button";
import { Input } from "../components/login/form";
import { stringWithFormat } from "../utils/locale";
import TeacherSearchPage from "./teacher-search";
import TeacherPendingPage from "./teacher-pending";
import TeacherInactivePage from "./teacher-inactive";
// import Space from "../components/spacing";

const FullWidthSection = styled(Container)`
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 40px);
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  padding-top: 20px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;

  & > input {
    flex: 1;
    margin-right: -8px;
    font-size: 1em;
    padding: 10px;
    line-height: 1em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & > button {
    line-height: 0.7em;
    height: min-content;
  }
`;

export const MessagesContainer = styled.ul`
  list-style: none;
  padding: 0px;
  max-height: 50vh;
  overflow-y: scroll;
`;

export const MessageContainer = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 3px;
    background-color: ${props =>
      props.from === "student" ? "#53adec" : "#dadada"};
    color: ${props => (props.from === "student" ? "white" : "#353535")};
    max-width: 60%;
  }

  & > div.bottomMessages {
    padding: 0;
    background: none;
    color: none;
    float: left;
    clear: both;
  }

  & > span.timestamp {
    color: #b9b9b9;
    font-size: 0.7em;
    margin-bottom: 10px;
  }

  & > span.user {
    font-weight: bold;
    margin-bottom: 5px;
  }
`;

type CouchingPagePropsType = {
  coaching: {
    name: string,
    teacher: string,
    student: string
  },
  messages: TestPageMessages,
  language: SupportedLanguage
};

export const CouchingPage = ({
  coaching,
  messages,
  language
}: CouchingPagePropsType) => {
  const { teacher, student, name } = coaching;
  const [conversation, setConversation] = React.useState([]);
  const [inputText, setInputText] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const inputEl = React.useRef(null);
  const messagesContainerEl = React.useRef(null);

  const fetchConversation = React.useCallback(() => {
    setLoading(true);
    callAPI(`/coaching/${teacher}/student/${student}/conversation`)
      .then(data => {
        setConversation(data);
        setLoading(false);
        if (inputEl.current) {
          inputEl.current.focus();
        }
        if (messagesContainerEl.current) {
          messagesContainerEl.current.scrollIntoView();
        }
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(error.message);
      });
  }, [teacher, student]);

  const postMessage = () => {
    setLoading(true);
    callAPI(`/coaching/${teacher}/student/${student}/conversation`, {
      message: inputText
    })
      .then(data => {
        setInputText("");
        fetchConversation();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(error.message);
      });
  };

  React.useEffect(() => {
    fetchConversation();
  }, [fetchConversation]);

  return (
    <FullWidthSection>
      {errorMessage && <p>{errorMessage}</p>}

      {name && (
        <p>
          {stringWithFormat(messages.connectedTo, {
            $partner: name
          })}
        </p>
      )}

      {conversation.length > 0 && (
        <MessagesContainer>
          {conversation.map((c, i) => {
            const created = moment(c.createdAt);
            const day7 = moment().subtract(1, "week");
            const formattedDate = created.isBefore(day7)
              ? created.format("LL LT")
              : created.fromNow();
            return (
              <MessageContainer key={i} from={c.from}>
                <span className="timestamp user">
                  {c.from === "teacher"
                    ? name || messages.teacher
                    : messages.you}
                </span>
                <div>{c.content}</div>
                <span className="timestamp">{formattedDate}</span>
                <div className="bottomMessages" ref={messagesContainerEl} />
              </MessageContainer>
            );
          })}
        </MessagesContainer>
      )}
      <Form
        onSubmit={e => {
          e.preventDefault();
          postMessage();
        }}
      >
        <Input
          innerRef={inputEl}
          disabled={loading}
          placeholder={stringWithFormat(messages.askPlaceholder, {
            $partner: name
          })}
          value={inputText}
          onChange={e => setInputText(e.target.value)}
        />
        <Button type="submit" disabled={loading}>
          {loading ? messages.sending : messages.send}
        </Button>
      </Form>
    </FullWidthSection>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  messages: state.language.messages,
  coaching: state.user.coaching,
  language: state.language.selected
});

// if there is coaching prop and the name of teacher, show the coaching page.
// else show connect to teacher page
export const CoachingContainerPage = props => {
  const { coaching } = props;

  if (coaching && coaching.name) {
    if (coaching.status === "active") {
      return <CouchingPage {...props} />;
    } else if (coaching.status === "pending") {
      return <TeacherPendingPage teacher={coaching.name} />;
    } else if (coaching.status === "inactive") {
      return <TeacherInactivePage teacher={coaching.name} />;
    }
  }

  return <TeacherSearchPage {...props} />;
};

const ConnectedCouchingPage = connect(mapStateToProps)(CoachingContainerPage);

export default withRouter(ConnectedCouchingPage);
