// @flow

import styled from "styled-components";

import { Body } from "./text";
import type { ThemePropsType } from "./theme";

type InfoBoxPropsType = {
  theme: ThemePropsType,
  message: string
};
const InfoBox = styled(Body)`
  background-color: ${(props: InfoBoxPropsType): string =>
    props.theme.color.error};
  color: white;
  padding: 20px;
  text-align: center;
  margin-bottom: 1em;
`;

export default InfoBox;
