// @flow

import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Body } from "./text";

const Container = styled.div`
  padding: 8px 20px;
  border-radius: 20px;
  background-color: ${props =>
    props.userIsRight ? props.theme.color.secondary : props.theme.color.red};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Text = styled(Body)`
  color: white;
  text-align: center;
  padding: 0;
  margin: 0;
`;
const Emoji = styled.span.attrs({
  role: "img",
  "aria-label": props => (props.userIsRight ? "Correct" : "False")
})`
  margin-right: 10px;
`;
const emojiComponent = userIsRight => {
  const emoji = userIsRight ? "👍" : "😭";
  return <Emoji userIsRight={userIsRight}>{emoji}</Emoji>;
};
export type RightWrongPropsType = {
  values: {
    userIsRight: boolean
  },
  messages: {
    rightAnswer: string,
    wrongAnswer: string
  }
};
export const RightWrong = ({
  values: { userIsRight },
  messages
}: RightWrongPropsType) => (
  <Container userIsRight={userIsRight}>
    {emojiComponent(userIsRight)}
    <Text>
      {userIsRight ? `${messages.rightAnswer}` : `${messages.wrongAnswer}`}
    </Text>
  </Container>
);

const mapStateToProps = (state, props) => ({
  ...props,
  messages: state.language.messages
});

export default connect(mapStateToProps)(RightWrong);
