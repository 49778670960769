// @flow

import React from "react";
import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.img`
  display: inline-block;
  animation: ${rotate360} 2s linear infinite;
  width: ${props => props.width || 15}px;
  height: ${props => props.height || 15}px;
`;

const DefaultSpinner = (props: any) => (
  <Spinner
    src={
      props.light ? require("./spinner-light.png") : require("./spinner.png")
    }
    {...props}
  />
);

export const LightSpinner = (props: any) => <DefaultSpinner {...props} light />;

export const BigSpinner = (props: any) => (
  <DefaultSpinner width={50} height={50} {...props} />
);

export default DefaultSpinner;
