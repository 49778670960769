// @flow

import * as React from "react";
import { Body } from "./text";
import styled from "styled-components";

const SectionTitleText = styled(Body)`
  text-align: center;
  color: #a7a7a7;
  background-color: ${(props) => props.theme.color.raisedBackground};
  z-index: 1;
  padding: 0 20px;
`;

const SectionTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  justify-content: space-around;
  position: relative;
`;

const Line = styled.div`
  height: 1px;
  border-bottom: solid 2px #dadada;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
`;

type SectionTitleType = {
  title: string,
};
const SectionTitle = ({ title }: SectionTitleType) => (
  <SectionTitleContainer>
    <Line />
    <SectionTitleText>{title}</SectionTitleText>
  </SectionTitleContainer>
);

export default SectionTitle;
