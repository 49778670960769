// @flow

export type TestType = {
  created: string,
  duration: number,
  elapsed: number,
  flavour: string,
  questionSet: string,
  path: string,
  percent: number,
  points: number,
  status: "passed" | "failed"
};
export type TestsStateType = {
  testInProgress: TestType,
  history: Array<TestType>
};

export default (
  state: TestsStateType = { testInProgress: {}, history: [] },
  action: any
) => {
  switch (action.type) {
    case "SET_TEST_IN_PROGRESS": {
      return {
        ...state,
        testInProgress: action.payload
      };
    }
    case "SET_TESTS_HISTORY": {
      return {
        ...state,
        history: action.payload
      };
    }
    default:
  }
  return state;
};
