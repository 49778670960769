// @flow

import * as React from "react";
import { connect } from "react-redux";
import type { AppStateType } from "../redux/reducers";
import { withRouter, type LocationShape } from "react-router-dom";
import { compose, withHandlers, withStateHandlers } from "recompose";
import { callAPI } from "../utils/api";
import DataLoader from "./data_loader";
import {
  LearnCard,
  type AnswerType,
  type LearnCardPropsType
} from "./learn_card";

export const userIsRight = (answers: Array<AnswerType>) => {
  var isCorrect = true;
  if (answers.length === 1) {
    // handle number answer
    const { inputAnswer = "", number } = answers[0];
    const normalizeAnswer = answer =>
      answer
        .toString()
        .trim()
        .toLowerCase()
        .replace(".", "-")
        .replace(",", "-");
    return normalizeAnswer(inputAnswer) === normalizeAnswer(number);
  }
  for (var i = 0; i < answers.length; i++) {
    if (
      (answers[i].isCorrect && !answers[i].selected) ||
      (!answers[i].isCorrect && answers[i].selected)
    ) {
      isCorrect = false;
      break;
    }
  }
  return isCorrect;
};

export const defaultOnClickReport = (
  code: string,
  messages: Object,
  selectedLanguage: string,
  country: string,
  name: string
) => {
  var subject = messages.reportProblemSubject;
  subject = subject.replace("%questionCode%", code);
  subject = subject.replace("%language%", selectedLanguage);
  subject = subject.replace("%country%", country);
  subject = subject.replace("%type%", name);
  subject = subject.replace("%version%", "web");

  var body = messages.reportProblemBody;
  body = body.replace("%questionCode%", code);

  const href = "mailto:crash@itheorie.ch?subject=" + subject + "&body=" + body;
  document.location.href = href;
};

const learnCardStateFromProps = props => {
  const userDidAnswer =
    props.userAnswers.length !== 0 ||
    props.userAnsweredWithoutSelectingAnyAnswers;
  var isCorrect = false;
  const answers = props.answers.map((a, i) => ({
    ...a,
    inputAnswer: props.userAnswers[i]
  }));
  if (userDidAnswer) {
    isCorrect = userIsRight(answers);
  }

  return {
    isSubmittingAnswer: false,
    userIsRight: isCorrect,
    answers,
    showExplanation: userDidAnswer,
    isSingleAnswer: props.question.single_choice
  };
};

// add state and handlers to learn card
const WithStateHandlers = compose(
  withStateHandlers(learnCardStateFromProps, {
    setIsSubmittingAnswer: () => isSubmittingAnswer => ({
      isSubmittingAnswer
    }),
    setUserIsRight: () => userIsRight => ({ userIsRight }),
    setAnswers: () => answers => ({ answers }),
    setShowExplanation: () => showExplanation => ({ showExplanation }),
    setIsFavourite: () => isFavourite => ({ isFavourite }),
    setNextPath: () => nextPath => ({ nextPath }),
    setPreviousPath: () => previousPath => ({ previousPath })
  }),
  withHandlers({
    onClickAnswer:
      ({ isSingleAnswer, answers, setAnswers }: LearnCardPropsType) =>
      (answer: AnswerType) => {
        const newAnswers = answers.map(a => ({
          ...a,
          selected: !isSingleAnswer
            ? a.answer_id === answer.answer_id
              ? !a.selected
              : a.selected
            : a.answer_id === answer.answer_id
        }));
        setAnswers(newAnswers);
      },
    onChangeInputAnswer:
      ({ answers, setAnswers }) =>
      (inputAnswer: string) => {
        const newAnswers = answers.map(a => ({
          ...a,
          inputAnswer
        }));
        setAnswers(newAnswers);
      },
    onClickCorrect:
      (props: LearnCardPropsType) =>
      (
        answers: Array<AnswerType>,
        questionCode: string,
        category: string,
        sessionId: string,
        index: number,
        isInRunningTest: boolean
      ) => {
        if (props.onClickCorrect) {
          return props.onClickCorrect(
            answers,
            questionCode,
            category,
            sessionId,
            index,
            isInRunningTest
          );
        }
        props.setIsSubmittingAnswer(true);
        const isCorrect = userIsRight(answers);

        var answersToSubmit = [];
        if (answers.length > 1) {
          answersToSubmit = answers
            .filter(a => a.selected)
            .map(a => a.answer_id)
            .join(";");
        } else {
          answersToSubmit = answers[0].inputAnswer;
        }

        // submit answer
        callAPI(`/question/${questionCode}/answer`, {
          sessionId,
          category,
          questionCode,
          isCorrect,
          answers: answersToSubmit,
          enteredAnswer: answersToSubmit
        }).then(() => {
          if (props.isInRunningTest) {
            if (props.nextPath) {
              props.history.push(props.history.location.pathname, {
                apiPath: props.nextPath
              });
            } else {
              props.history.push(props.resultURL);
            }
          } else {
            props.setIsSubmittingAnswer(false);
            props.setShowExplanation(true);
            props.setUserIsRight(isCorrect);

            return null;
          }
        });
      },
    onClickBack: (props: LearnCardPropsType) => () => {
      if (props.onClickBack) {
        return props.onClickBack();
      }
      props.setIsSubmittingAnswer(true);
      if (props.previousPath) {
        props.history.push(props.history.location.pathname, {
          apiPath: props.previousPath
        });
      } else {
        props.history.push("/dashboard");
      }
    },
    onClickNext:
      (props: LearnCardPropsType) =>
      (currentIndex: number, questionId: string, sessionId: string) => {
        if (props.onClickNext) {
          return props.onClickNext(currentIndex, questionId, sessionId);
        }
        props.setIsSubmittingAnswer(true);
        if (props.nextPath) {
          props.history.push(props.history.location.pathname, {
            apiPath: props.nextPath
          });
        } else {
          props.history.push("/dashboard");
        }
      },
    onClickFavourite:
      (props: LearnCardPropsType) =>
      (questionCode: string, categoryCode: string, index: number) => {
        callAPI(
          `/question/${questionCode}/togglefav?categoryCode=${categoryCode}&currentIndex=${index}`
        ).then(result => {
          props.setIsFavourite(result.isFavourite);
          if (result.nextPath && result.nextPath.length > 0) {
            props.setNextPath(result.nextPath);
          }
          if (result.previousPath && result.previousPath.length > 0) {
            props.setPreviousPath(result.previousPath);
          }
        });
      },
    onClickReport:
      ({
        question: { code },
        messages,
        selectedLanguage,
        flavour: { country, name }
      }: LearnCardPropsType) =>
      () => {
        defaultOnClickReport(code, messages, selectedLanguage, country, name);
      }
  })
)(LearnCard);

// get the messages from redux
const mapStateToProps = (state: AppStateType) => ({
  messages: state.language.messages,
  selectedLanguage: state.language.selected,
  flavour: state.flavour
});
const EnhancedLearnCard = withRouter(
  connect(mapStateToProps)(WithStateHandlers)
);

export default EnhancedLearnCard;

export class LearnDataProvider extends React.Component<{
  location: LocationShape,
  Component: React.Node,
  onFetchQuestion: (question: any) => void
}> {
  render() {
    // use the current url by default
    var apiPath = this.props.location.pathname || "";

    // but if there's apiPath in the location's state, use that.
    if (
      this.props.location.state &&
      this.props.location.state.apiPath &&
      this.props.location.state.apiPath !== null
    ) {
      apiPath = this.props.location.state.apiPath;
    }
    const { Component, ...rest } = this.props;
    return (
      <DataLoader
        passDataToComponent={true}
        pathname={apiPath}
        component={Component}
        onDataFetch={this.props.onFetchQuestion}
        {...rest}
      />
    );
  }
}
