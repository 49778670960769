// @flow

type ProductType = {
  icon: string,
  title: Object,
  path: string
};

export type ProductsState = Array<ProductType>;

export type SetProductsAction = {
  type: "SET_PRODUCTS",
  payload: ProductsState
};

export default (
  state: ProductsState = [],
  action: SetProductsAction
): ProductsState => {
  switch (action.type) {
    case "SET_PRODUCTS": {
      return action.payload;
    }
    default:
  }
  return state;
};
