// @flow

import { combineReducers } from "redux";
import type { LanguageState } from "./language";
import type { FlavourState } from "./flavour";
import type { ProductsState } from "./products";
import type { OverallProgressState } from "./overall_progress";
import type { GroupedCategoriesState } from "./grouped_categories";
import type { UserStateType } from "./user";
import type { TestsStateType } from "./tests";
import type { ThemePropsType } from "../../components/theme";

export type AppStateType = {
  language: LanguageState,
  flavour: FlavourState,
  products: ProductsState,
  overallProgress: OverallProgressState,
  groupedCategories: GroupedCategoriesState,
  user: UserStateType,
  tests: TestsStateType,
  theme: ThemePropsType
};

const app = combineReducers({
  theme: require("./theme").default,
  flavour: require("./flavour").default,
  language: require("./language").default,
  products: require("./products").default,
  overallProgress: require("./overall_progress").default,
  groupedCategories: require("./grouped_categories").default,
  user: require("./user").default,
  tests: require("./tests").default
});

export default app;
