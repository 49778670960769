import "promise-polyfill/src/polyfill";
import "whatwg-fetch";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { unregister } from "./registerServiceWorker";

require("./polyfill").default();

ReactDOM.render(<App />, document.getElementById("root"));
unregister();
