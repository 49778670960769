// @flow

import * as React from "react";
import styled from "styled-components";
import SectionTitle from "../components/section_title";
import { Title, Body, Footer } from "../components/text";
import { LearnCard } from "../components/learn_card";
import Button, { CloseButton } from "../components/button";
import Space from "../components/spacing";
import type { AppStateType } from "../redux/reducers";
import type { FlavourState } from "../redux/reducers/flavour";
import { compose, withStateHandlers, withHandlers } from "recompose";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { callAPI } from "../utils/api";
import { smallScreenQuery, wideScreenQuery } from "../components/screen";
import {
  Modal,
  ModalContainer,
  FullWidthRaisedContainer,
} from "../components/container";
import Media from "react-media";
import DataLoader from "../components/data_loader";
import { defaultOnClickReport } from "../components/learn_card.container";

const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 10px auto;
`;
const TestResultContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    margin: 0;
    color: ${(props) =>
      props.success ? props.theme.color.secondary : props.theme.color.failed};
  }

  & > table {
    margin-top: 20px;

    tr > td {
      padding: 0 30px;

      p {
        margin: 5px 0;
      }
    }

    tr > td:first-child > p {
      color: ${(props) => props.theme.color.textLight};
    }

    tr > td:last-child > p {
      text-align: right;
    }
  }
`;
const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const FilterContainer = styled.div`
  width: 100%;
  a ~ a::before {
    content: "/";
    padding: 0 10px;
    color: #dadada;
  }
`;
const Filter = styled.a`
  text-decoration: none;
  color: ${(props) =>
    props.active ? props.theme.color.primary : props.theme.color.disabled};
  font-family: ${(props) => props.theme.font.family};
  cursor: ${(props) => (props.active ? "default" : "pointer")};
`;
const QuestionsListContainer = styled.div`
  flex: 0.5;
  padding: 10px 0 10px 10px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    flex: 1;
  }
`;
const QuestionsList = styled.ul`
  list-style: none;
  padding: 0;
  box-shadow: inset 0px -10px 6px 0px rgba(240, 240, 240, 255);
  max-height: 100vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  @media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    max-height: 100%;
    box-shadow: none;
  }
`;
const QuestionRow = styled.li`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: solid 1px ${(props) => props.theme.color.disabled};
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? props.theme.color.lightGray : "none"};

  &:hover {
    background-color: ${(props) => props.theme.color.primaryLight};
  }

  & > p:first-child {
    align-self: center;
    flex: 0.05;
    margin: 0;
    color: ${(props) => props.theme.color.dark};
  }
  & > img:nth-child(2) {
    width: 80px;
    height: 80px;
    object-fit: cover;
    background-color: ${(props) => props.theme.color.disabled};
    margin-right: 20px;
    border-radius: 5px;
  }

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    margin-bottom: 5px;
  }

  & > div > p {
    margin: 0;
  }

  & > div > p:first-child {
    font-size: ${(props) => props.theme.font.body.size};
  }

  & > div > p:last-child {
    margin-top: 10px;
  }

  & > img:last-child {
    width: 25px;
    height: 25px;
    align-self: center;
    margin-right: 20px;
  }
`;
const QuestionContainer = styled.div`
  flex: 0.5;
  background-color: ${(props) => props.theme.color.lightGray};
  border-radius: 10px;
  padding: 20px;
  align-self: baseline;

  @media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    flex: 1;
  }
`;
const ModalCloseButton = styled(CloseButton)`
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10;
`;
const TestResultProperties = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;

  & > li {
    padding: 0 10px;
  }
  & > li > p > span:first-child {
    color: ${(props) => props.theme.color.textLight};
    margin-right: 10px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    flex-direction: column;
  }
`;

type TestResultPageMessage = {
  testResult: string,
  youPassed: string,
  youFailed: string,
  score: string,
  percentage: string,
  duration: string,
  date: string,
  testQuestions: string,
  allQuestions: string,
  successQuestions: string,
  failedQuestions: string,
  tryAgain: string,
  allTests: string,
  closeTestResult: string,
  zeroCorrect: string,
  zeroMistake: string,
  rightAnswer: string,
  wrongAnswer: string,
  errorPoints: string,
  points: string,
};
type TestResultPagePropsType = {
  sessionId: string,
  flavour: FlavourState,
  testResult: {
    displayPercentage: boolean,
    score: number,
    percentage: number,
    status: "passed" | "failed",
    duration: string,
    date: string,
    points: number,
    errorPoints: number,
    maxErrorPoints: number,
    maxPoints: number,
  },
  testItems: Array<{
    flavour: FlavourState,
    question: {
      id: string,
      code: string,
      image: string,
      texts: Object,
      names: Object,
      score: number,
      isSingleAnswer: boolean,
      isInputAnswer: boolean,
      index: number,
      isVideo: boolean,
      userIsRight: boolean,
    },
    answers: Array<AnswerType>,
  }>,
  messages: TestResultPageMessage,
  selectedLanguage: string,
  selectedQuestionIndex: number,
  teacherPOV: boolean,
  selectedFilter: "all" | "correct" | "failed",
  setSelectedFilter?: (filter: string) => void,
  setSelectedQuestionIndex?: (questionIndex: number) => void,
  onClickTryAgain?: () => void,
  history: { push: (path: string, state?: any) => void },
  onClickFavourite: (questionCode: string) => void,
  onClickReport: (
    code: string,
    messages: Object,
    selectedLanguage: string,
    country: string,
    name: string
  ) => void,
};
export const TestResultPage = ({
  messages,
  testResult,
  testItems,
  selectedFilter = "all",
  selectedQuestionIndex = -1,
  setSelectedFilter = () => {},
  setSelectedQuestionIndex = () => {},
  history,
  selectedLanguage,
  onClickFavourite,
  onClickReport,
  flavour,
  teacherPOV,
}: TestResultPagePropsType) => {
  const filters = {
    all: messages.allQuestions,
    correct: messages.successQuestions,
    failed: messages.failedQuestions,
  };
  var filteredItems = testItems;
  if (selectedFilter !== "all") {
    filteredItems = testItems.filter((t) => {
      if (selectedFilter === "correct") {
        return t.question.userIsRight;
      }
      return !t.question.userIsRight;
    });
  }
  var selectedItem = null;
  var selectedIndexForSelectedFilter = selectedQuestionIndex;
  if (filteredItems.length > 0) {
    if (
      selectedQuestionIndex >= 0 &&
      selectedQuestionIndex < filteredItems.length
    ) {
      selectedItem = filteredItems[selectedQuestionIndex];
    }
  }

  const learnCardProps = selectedItem
    ? {
        ...selectedItem.question,
        answers: selectedItem.answers,
        question: selectedItem.question,
        showExplanation: true,
        isInTestResult: true,
        messages,
        selectedLanguage,
        onClickFavourite,
        onClickReport,
        flavour,
      }
    : null;

  var testResultProperties = [[messages.duration, testResult.duration]];
  if (!testResult.displayPercentage) {
    testResultProperties = [
      ...testResultProperties,
      [messages.percentage, `${Math.ceil(testResult.percentage)}%`],
      [messages.score, testResult.score],
      [
        messages.errorPoints,
        `${testResult.errorPoints} / ${testResult.maxErrorPoints}`,
      ],
    ];
  } else {
    testResultProperties = [
      ...testResultProperties,
      [messages.points, `${testResult.points} / ${testResult.maxPoints}`],
      [messages.score, testResult.score],
      [messages.percentage, `${Math.ceil(testResult.percentage)}%`],
    ];
  }
  var title =
    testResult.status === "passed" ? messages.youPassed : messages.youFailed;
  if (teacherPOV) {
    title =
      testResult.status === "passed"
        ? messages.studentPassedTest
        : messages.studentFailedTest;
  }
  return (
    <Container>
      <FullWidthRaisedContainer>
        <SectionTitle title={messages.testResult} />
        <TestResultContainer success={testResult.status === "passed"}>
          <Title>{title}</Title>
          <TestResultProperties>
            {testResultProperties.map((row, i) => (
              <li key={i}>
                <Body>
                  <span>{row[0]}</span>
                  <span>{row[1]}</span>
                </Body>
              </li>
            ))}
          </TestResultProperties>
          <Space v={20} />
          {!teacherPOV && (
            <Button primary onClick={() => history.push("/dashboard/test")}>
              {messages.closeTestResult}
            </Button>
          )}
        </TestResultContainer>
        <SectionTitle title={messages.testQuestions} />

        <QuestionsContainer>
          <QuestionsListContainer>
            <FilterContainer>
              {Object.keys(filters).map((f, i) => (
                <Filter
                  onClick={() => setSelectedFilter(f)}
                  key={i}
                  active={f === selectedFilter}
                >
                  {filters[f]}
                </Filter>
              ))}
            </FilterContainer>
            <QuestionsList>
              {filteredItems.map((t, i) => (
                <QuestionRow
                  key={i}
                  onClick={() => setSelectedQuestionIndex(i)}
                  selected={i === selectedIndexForSelectedFilter}
                >
                  <Footer>{i + 1}</Footer>
                  {t.question.image && (
                    <img src={t.question.image} alt={t.question.code} />
                  )}
                  <div>
                    <Title>{t.question.code}</Title>
                    <Body>{t.question.names[selectedLanguage]}</Body>
                  </div>
                  <img
                    alt={
                      t.question.userIsRight
                        ? messages.rightAnswer
                        : messages.wrongAnswer
                    }
                    src={
                      t.question.userIsRight
                        ? require("../components/check.svg")
                        : require("../components/wrong.svg")
                    }
                  />
                </QuestionRow>
              ))}
              {filteredItems.length === 0 && selectedFilter === "correct" && (
                <Body>{messages.zeroCorrect}</Body>
              )}
              {filteredItems.length === 0 && selectedFilter === "failed" && (
                <Body>{messages.zeroMistake}</Body>
              )}
            </QuestionsList>
          </QuestionsListContainer>
          <Media query={wideScreenQuery}>
            {(match) =>
              match
                ? learnCardProps && (
                    <QuestionContainer>
                      <LearnCard {...learnCardProps} teacherPOV={teacherPOV} />
                    </QuestionContainer>
                  )
                : null
            }
          </Media>

          {learnCardProps && (
            <Media query={smallScreenQuery}>
              {(match) =>
                match ? (
                  <Modal>
                    <ModalContainer>
                      <ModalCloseButton
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedQuestionIndex(-1);
                        }}
                      />
                      <QuestionContainer>
                        <LearnCard {...learnCardProps} />
                      </QuestionContainer>
                    </ModalContainer>
                  </Modal>
                ) : null
              }
            </Media>
          )}
        </QuestionsContainer>
      </FullWidthRaisedContainer>
    </Container>
  );
};

const WithState = compose(
  withStateHandlers(
    (props) => ({
      testItems: props.testItems,
      selectedQuestionIndex: -1,
      selectedFilter: "all",
    }),
    {
      setSelectedFilter: () => (selectedFilter: string) => ({ selectedFilter }),
      setSelectedQuestionIndex: () => (selectedQuestionIndex: number) => ({
        selectedQuestionIndex,
      }),
      setTestItem: (state, props) => (testItem: Object) => {
        const newTestItems = state.testItems.map((t) => {
          if (t.question.code === testItem.question.code) {
            return testItem;
          }
          return t;
        });
        return {
          testItems: newTestItems,
        };
      },
    }
  ),
  withHandlers({
    onClickFavourite: (props) => (questionCode: string) => {
      callAPI(`/question/${questionCode}/togglefav`).then((result) => {
        const testItem = props.testItems.filter(
          (t) => t.question.code === questionCode
        )[0];
        props.setTestItem({
          ...testItem,
          question: {
            ...testItem.question,
            isFavourite: result.isFavourite,
          },
        });
      });
    },
    onClickReport: () => (code, messages, selectedLanguage, country, name) => {
      defaultOnClickReport(code, messages, selectedLanguage, country, name);
    },
  })
)(TestResultPage);

const mapStateToProps = (state: AppStateType) => ({
  messages: state.language.messages,
  selectedLanguage: state.language.selected,
  flavour: state.flavour,
});
const ConnectedTestResultPage = connect(mapStateToProps)(WithState);

const TestResultPageWithData = (props: any) => (
  <DataLoader
    pathname={props.location.pathname}
    component={ConnectedTestResultPage}
    {...props}
  />
);

const WithRouter = withRouter(TestResultPageWithData);

export default WithRouter;
