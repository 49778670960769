// @flow

import React, { forwardRef } from "react";
import styled from "styled-components";
import type { ThemePropsType } from "./theme";
import { LightSpinner } from "./spinner";

type ButtonProps = {
  primary?: boolean,
  secondary?: boolean,
  theme?: ThemePropsType
};

const colorTheme = ({ theme, secondary }: ButtonProps) =>
  theme ? (secondary ? theme.color.secondary : theme.color.primary) : "black";

const Button = styled.button`
  color: white;
  background: ${colorTheme};
  font-size: 18px;
  padding: 14px 16px;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  align-content: center;
  :hover {
    opacity: 0.7;
  }
  :disabled {
    cursor: ${(props) => (props.isInProgress ? "progress" : "not-allowed")};
    background: ${({ theme }: { theme: ThemePropsType }) =>
      theme.color.disabled};
    opacity: 1;
  }
`;

export const ButtonWithSpinner = ({
  children,
  showSpinner = false,
  ...rest
}: {
  children: any,
  showSpinner: boolean
}) => (
  <Button {...rest}>
    {children} {showSpinner && <LightSpinner />}
  </Button>
);

export const Link = styled.a`
  color: ${({ theme }: { theme: ThemePropsType }) => theme.color.tertiary};
  font-family: ${({ theme }: { theme: ThemePropsType }) => theme.font.family};
  text-decoration: underline;
`;

const Close = styled.button`
  width: 30px;
  height: 30px;
  padding: 0;
  padding-bottom: 3px;
  transform: rotate(45deg);
  font-size: 20px;
  border-radius: 15px;
  border: none;
  background-color: ${({ theme }: { theme: ThemePropsType }) =>
    theme.color.primary};
  color: white;
  cursor: pointer;
  :focus {
    outline: none;
  }
  :hover {
    transform: scale(1.5) rotate(45deg);
  }
`;

type IconButtonPropsType = {
  href: string,
  selected?: boolean,
  selectedIcon?: string,
  normalIcon?: string
};
export const IconButton = styled.a.attrs({
  href: (props) => props.href || "#"
})`
  background: url(${(props) =>
      props.selected ? props.selectedIcon : props.normalIcon})
    no-repeat 50% 50%;
  height: 25px;
  width: 25px;
  border: none;
  background-size: contain;
  text-decoration: none;
  display: block;
  margin: 5px;
  &:hover {
    transform: scale(0.9);
  }
`;

export const FavouriteButton = forwardRef(
  (props: IconButtonPropsType, ref: any) => (
    <IconButton
      ref={ref}
      {...props}
      selectedIcon={require("./heart-filled.png")}
      normalIcon={require("./heart.png")}
    />
  )
);

export const ReportButton = forwardRef(
  (props: IconButtonPropsType, ref: any) => (
    <IconButton
      ref={ref}
      {...props}
      selectedIcon={require("./report.png")}
      normalIcon={require("./report.png")}
    />
  )
);

export const CloseButton = (props: any) => <Close {...props}>+</Close>;

export const LinkButton = styled(Link)`
  text-decoration: none;
  background-color: ${(props) => props.theme.color.secondary};
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
`;

export default Button;
