// @flow
import store from "../redux/store";

export const callAPI = (path: string, params: ?Object = null): Promise<any> => {
  var apiPath = path;
  if (!path.startsWith("/api")) {
    apiPath = `/api${path}`;
  }

  var headers = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache"
  };
  var apiParams = {
    credentials: "same-origin",
    headers,
    cache: "no-cache"
  };

  if (params) {
    apiParams = {
      ...apiParams,
      method: "POST",
      body: JSON.stringify({
        ...params,
        lang: store.getState().language.selected
      })
    };
  }

  return fetch(apiPath, apiParams)
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else if (response.status === 500) {
        return response.text().then(result => {
          return {
            error: result.error || result
          };
        });
      }
      return response.json().then(result => {
        return {
          error: result.error
        };
      });
    })
    .catch(e => console.log(e));
};
