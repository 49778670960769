// @flow

import type { ProgressType } from "../../components/dashboard/overall_progress";

export type CategoryType = {
  code: string,
  image: string,
  names: Object,
  url: string,
  progress: Array<ProgressType>,
  total: number
};
export type GroupedCategoryType = {
  title: string,
  categories: Array<CategoryType>
};
export type GroupedCategoriesState = Array<GroupedCategoryType>;

export default (
  state: GroupedCategoriesState = [],
  action: any
): GroupedCategoriesState => {
  switch (action.type) {
    case "SET_GROUPED_CATEGORIES": {
      return action.payload;
    }
    default:
  }
  return state;
};
