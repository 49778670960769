// @flow

import * as React from "react";
import styled from "styled-components";
import { Body } from "../components/text";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import type { AppStateType } from "../redux/reducers";
import { withProps, compose } from "recompose";
import LearnCard, {
  LearnDataProvider
} from "../components/learn_card.container";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
`;

const TitleContainer = styled(Body)`
  text-align: center;
  margin-bottom: 2em;

  span ~ span::before {
    content: ">";
    padding: 0 10px;
    color: #dadada;
  }
`;

type LearnPagePropsType = {
  index: number,
  total: number,
  category: { names: Object },
  messages: {
    allCategories: string,
    backToSearch: string
  },
  fromSearch: string,
  history: any,
  selectedLanguage: string
};

export const LearnPage = (props: LearnPagePropsType) => {
  const {
    index = 0,
    total = 0,
    category,
    messages,
    fromSearch,
    history,
    selectedLanguage = "en"
  } = props;
  return (
    <Container>
      {total > 0 && (
        <React.Fragment>
          <TitleContainer>
            <span>
              <Link to={fromSearch || "/dashboard"}>
                {fromSearch ? messages.backToSearch : messages.allCategories}
              </Link>
            </span>
            <span>
              {category.names[selectedLanguage]}{" "}
              {total > 1 && `(${`${index + 1}/${total}`})`}
            </span>
          </TitleContainer>
        </React.Fragment>
      )}
      <LearnCard
        onClickNext={fromSearch && (() => history.goBack())}
        {...props}
      />
    </Container>
  );
};

const Enhanced = compose(
  withProps(({ location }) => ({
    fromSearch:
      (location && location.state && location.state.fromSearch) || null
  }))
)(LearnPage);

const mapStateToProps = (state: AppStateType) => ({
  messages: state.language.messages,
  selectedLanguage: state.language.selected
});
const ConnectedLearnPage = connect(mapStateToProps)(Enhanced);

const WithData = props => (
  <LearnDataProvider
    location={props.location}
    Component={ConnectedLearnPage}
    {...props}
  />
);

const WithRouter = withRouter(WithData);

export default WithRouter;
