import * as React from "react";
import styled from "styled-components";
import { Container } from "../components/container";
import { Input } from "../components/login/form";
import Button from "../components/button";
import { callAPI } from "../utils/api";
import { stringWithFormat } from "../utils/locale";
import { connect } from "react-redux";

const SearchContainer = styled(Container)`
  padding: 20px;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const SearchInput = styled(Input)`
  flex: 1;
  margin-bottom: 0;
  margin-right: 20px;
`;

const ResultContainer = styled(Container)`
  width: 100%;
  align-items: flex-start;
  padding: 20px 0;

  & > ul {
    margin-block-start: 0;
    padding-inline-start: 0;
  }
`;

const ResultRow = styled.li`
  padding-left: 0;
  padding-top: 0.5em;
  & > button {
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 15px;
    background: #8bc34a;
    text-decoration: underline;
  }
`;

const TeacherSearchPage = props => {
  const { messages, user, email, flavour } = props;
  const [isSearching, setIsSearching] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [error, setError] = React.useState("");
  const [teachers, setTeachers] = React.useState([]);
  const [noTeachers, setNoTeachers] = React.useState(false);
  const [submittingRequest, setSubmittingRequest] = React.useState(false);

  function fetchSearchResult(e) {
    e.preventDefault();

    setIsSearching(true);

    callAPI("/teachers", {
      query: searchQuery.trim()
    })
      .then(response => {
        setTeachers(response);
        if (response.length === 0) {
          setNoTeachers(true);
        } else {
          setNoTeachers(false);
        }
        setIsSearching(false);
      })
      .catch(err => {
        setError(err.message);
        setIsSearching(false);
      });
  }

  function sendRequestToConnect(name, teacherId, teacherName) {
    setSubmittingRequest(true);

    callAPI("/api/request-connect-teacher", {
      flavour,
      email,
      elearningId: user,
      name,
      teacherId
    })
      .then(response => {
        if (response.result.status) {
          window.location.reload();
        } else {
          window.alert(
            stringWithFormat(messages.cannotAcceptRequest, {
              $teacher: teacherName
            })
          );
          setSubmittingRequest(false);
        }
      })
      .catch(err => {
        setSubmittingRequest(false);
        window.alert(err.message);
      });
  }

  if (submittingRequest) {
    return (
      <SearchContainer>
        <p>{messages.sendingRequest}</p>
      </SearchContainer>
    );
  }

  return (
    <SearchContainer>
      <p>{messages.notConnectedToTeacher}</p>
      {error && <div>{error}</div>}
      <Form onSubmit={fetchSearchResult}>
        <SearchInput
          disabled={isSearching}
          placeholder={messages.searchTeacherPlaceholder}
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
        <Button disabled={isSearching} type="submit">
          {messages.search}
        </Button>
      </Form>

      <ResultContainer>
        {teachers && teachers.length > 0 && (
          <React.Fragment>
            <p>
              {stringWithFormat(messages.foundNumberOfTeachers, {
                $number: teachers.length
              })}
            </p>
            <ul>
              {teachers.map((t, i) => {
                return (
                  <ResultRow key={i}>
                    {`${t.name}. `}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        t.mapLink ||
                        createGoogleMapLink(t.address, t.land, t.ort, t.plz)
                      }
                    >{`${t.address} ${t.land} ${t.ort} ${t.plz}`}</a>
                    <Button
                      onClick={e => {
                        const name = window.prompt(messages.provideName);
                        if (name) {
                          sendRequestToConnect(name, t.id, t.name);
                        }
                      }}
                    >
                      {messages.requestToConnectToTeacher}
                    </Button>
                  </ResultRow>
                );
              })}
            </ul>
          </React.Fragment>
        )}
        {noTeachers && <p>{messages.emptyTeachersSearchResult}</p>}
      </ResultContainer>
    </SearchContainer>
  );
};

export const createGoogleMapLink = (address, land, ort, plz) => {
  const a = address.replace(" ", "+");
  const l = land.replace(" ", "+");
  const o = ort.replace(" ", "+");
  const p = plz.replace(" ", "+");
  return `https://www.google.com/maps/place/${a}+${l}+${o}+${p}`;
};

const mapStateToProps = (state: AppStateType) => ({
  messages: state.language.messages,
  user: state.user.id,
  email: state.user.email,
  flavour: state.flavour.name
});

export default connect(mapStateToProps)(TeacherSearchPage);
