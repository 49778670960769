import styled from "styled-components";

const LanguageWarning = styled.p`
  background-color: #ffc107;
  padding: 20px;
  margin: 10px 0 30px 0;
  font-weight: bold;

  @media only screen and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    text-align: center;
  }
`;

export default LanguageWarning;
