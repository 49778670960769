// @flow

import React, { useEffect, useState } from "react";
import Grid from "styled-components-grid";
import { BigSpinner } from "../components/spinner";
import { Body } from "../components/text";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { callAPI } from "../utils/api";
import ReactGA from "react-ga";

type PaymentCompletedPageType = {
  messages: {
    processingPayment: string
  }
};

let repeatInterval = null;
const REPEAT_INTERVAL = 10000;
let apiIsCalled = false;

const PaymentCompletedPage = ({
  messages,
  ...rest
}: PaymentCompletedPageType) => {
  const {
    history,
    location: { search }
  } = rest;

  const [error, setError] = useState(null);
  const queries = search
    .substring(1)
    .split("&")
    .reduce((prev, curr) => {
      const [key, value] = curr.split("=");
      return {
        ...prev,
        [key]: value
      };
    }, {});

  let flavour;
  if (queries.appName) {
    flavour = queries.appName;
  }

  const [repeatCount, setRepeatCount] = useState(0);

  useEffect(() => {
    if (!apiIsCalled && flavour && repeatCount < 3) {
      apiIsCalled = true;
      repeatInterval = setTimeout(() => {
        const {
          platform,
          userAgent,
          appVersion,
          vendor,
          appCodeName,
          language
        } = window.navigator;
        const device = `Platform: ${platform}.\nUser agent: ${userAgent}.\nApp version: ${appVersion}.\nVendor: ${vendor}.\nApp code name: ${appCodeName}.\nLanguage: ${language}`;

        callAPI(`/api/purchase-complete`, {
          transactionUUID: queries.tid,
          appId: queries.appId,
          appName: queries.appName,
          device
        }).then(result => {
          if (result.code && result.email) {
            // Google analytics
            const analyticsPayload = {
              id: result.flavour.name,
              name: result.flavour.title,
              price: result.flavour.price.value,
              quantity: 1,
              currency: result.flavour.price.currency
            };
            ReactGA.plugin.execute("ec", "setAction", "purchase", {
              id: result.transactionId,
              revenue: result.flavour.price.value
            });
            ReactGA.event({
              category: "Purchase",
              action: "End Payment",
              label: "payment_success"
            });

            // bing
            window.uetq = window.uetq || [];
            window.uetq.push("event", "Sale", {});

            // google tag adwords
            if (
              result.flavour.gtag_conversion &&
              result.flavour.gtag_conversion["de"]
            ) {
              window.gtag("event", "conversion", {
                send_to: result.flavour.gtag_conversion["de"],
                transaction_id: result.transactionId,
                value: analyticsPayload.price,
                currency: analyticsPayload.currency.toUpperCase()
              });
            }

            // fb
            window.fbq("track", "Purchase", {
              value: analyticsPayload.price,
              currency: analyticsPayload.currency.toUpperCase(),
              content_ids: [analyticsPayload.id],
              content_type: "product"
            });

            setTimeout(() => {
              history.push(`/flavour/${flavour}`, {
                username: result.email,
                password: result.code
              });
            }, 3000);
          } else {
            if (repeatCount === 3) {
              setError(result.error);
            } else {
              if (!apiIsCalled) {
                setRepeatCount(c => c + 1);
              }
            }
            apiIsCalled = false;
          }
        });
      }, REPEAT_INTERVAL);
    } else {
      // history.push(`/products`);
      clearInterval(repeatInterval);
    }
  }, [search, flavour, history, queries, repeatCount]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch"
      }}>
      <Grid halign="center" width="100%">
        <Grid.Unit size={1 / 3}>
          {error && (
            <>
              <Body>{error}</Body>
              <Body>Transaction ID: {queries.tid}</Body>
              <Body>
                Please contact us at{" "}
                <a href="mailto:support@itheorie.ch">support@itheorie.ch</a>
              </Body>
            </>
          )}
          {!error && (
            <>
              <Body align="center">
                <BigSpinner />
              </Body>
              <Body align="center">{messages.processingPayment}</Body>
            </>
          )}
        </Grid.Unit>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  messages: state.language.messages
});

// add history props from react-router
const WithRouter = withRouter(connect(mapStateToProps)(PaymentCompletedPage));

export default WithRouter;
