import * as React from "react";
import styled from "styled-components";
import Grid from "styled-components-grid";
import OverallProgress from "../components/dashboard/overall_progress";
import Space from "../components/spacing";
import { LinkButton } from "../components/button";
import SectionTitle from "../components/section_title";
import GroupedCategoriesPage from "./categories";
import { FullWidthRaisedContainer } from "../components/container";

const OverallProgressContainer = ({ children }: { children: React.Node }) => (
  <Grid halign="center">
    <Grid.Unit size={{ desktop: 1, mobile: 1, tablet: 1 }}>
      {children}
    </Grid.Unit>
  </Grid>
);

// #dff1ff

const TruckPlusContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  background-color: ${(props) => props.theme.color.lightPrimary};
  border: solid 1px #dedede;
  align-items: center;
  justify-content: space-around;
  line-height: 25px;

  & > h2 {
    margin: 0;
    margin-bottom: 1em;
  }
  & > h3 {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 300;
  }

  & > p {
    margin-bottom: 2em;
  }
`;

const CategoriesPage = ({ messages, overallProgress, showTruckPlus }) => {
  return (
    <React.Fragment>
      <FullWidthRaisedContainer>
        <SectionTitle title={messages.yourProgress} />
        <OverallProgressContainer>
          <OverallProgress {...overallProgress} />
        </OverallProgressContainer>
        <Space v={30} />
        <GroupedCategoriesPage />
        {showTruckPlus && (
          <OverallProgressContainer>
            <TruckPlusContainer>
              <h3>{messages.truckPlusPromoTitle}</h3>
              <h2>{messages.truckPlusPromoSubtitle}</h2>
              <p>{messages.truckPlusPromoBody}</p>
              <LinkButton href={`/flavour/ch_truck_extended/buy`}>
                {messages.viewProduct}
              </LinkButton>
            </TruckPlusContainer>
          </OverallProgressContainer>
        )}
      </FullWidthRaisedContainer>
    </React.Fragment>
  );
};

export default CategoriesPage;
