// @flow

import styled from "styled-components";

import React from "react";
import Grid from "styled-components-grid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Container } from "../components/container";
import CategoryBox from "../components/categories/category_box";
import Space from "../components/spacing";
import SectionTitle from "../components/section_title";

import type { GroupedCategoryType } from "../redux/reducers/grouped_categories";
import type { AppStateType } from "../redux/reducers";

const FullWidthSection = styled(Container)`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
`;

const GroupedCategory = styled(FullWidthSection)`
  @media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    padding: 10px;
    width: 90%;
  }
`;

type CategoriesPageType = {
  groupedCategories: Array<GroupedCategoryType>,
  history: { push: (path: string) => void },
  selectedLanguage: string,
  messages: any
};
export const CategoriesPage = ({
  groupedCategories,
  history,
  selectedLanguage = "en",
  messages
}: CategoriesPageType) => (
  <FullWidthSection>
    {groupedCategories.map((g, i) => (
      <GroupedCategory key={i}>
        <SectionTitle title={messages[g.title] || g.title} />
        <Grid halign="center">
          {g.categories.map((cat, j) => {
            var categoryTitle = cat.names[selectedLanguage];
            if (cat.code === "98") {
              if (cat.total > 0) {
                categoryTitle = `${categoryTitle} (${cat.total})`;
              }
            }
            return (
              <Grid.Unit
                key={j}
                size={{ desktop: 1 / 3, mobile: 1, tablet: 1 / 3 }}
              >
                <CategoryBox
                  {...{
                    category: {
                      icon: cat.image,
                      title: categoryTitle,
                      code: cat.code
                    },
                    progress: cat.progress,
                    total: cat.total
                  }}
                  onClick={() => cat.total > 0 && history.push(cat.url)}
                  onClickProgress={(
                    code: string,
                    progress: string,
                    url: string
                  ) => history.push(url)}
                />
              </Grid.Unit>
            );
          })}
        </Grid>
        <Space v={30} />
      </GroupedCategory>
    ))}
  </FullWidthSection>
);

const mapStateToProps = (state: AppStateType) => ({
  groupedCategories: state.groupedCategories,
  selectedLanguage: state.language.selected,
  messages: state.language.messages
});
const ConnectedCategoriesPage = connect(mapStateToProps)(CategoriesPage);

export default withRouter(ConnectedCategoriesPage);
