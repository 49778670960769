// @flow

import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import app from "./reducers";

var middlewares;
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  middlewares = compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  middlewares = applyMiddleware(thunk);
}

const store = createStore(app, middlewares);

export default store;
