// @flow

import * as React from "react";
import { callAPI } from "../utils/api";
import { ModalContainer } from "./container";
import { BigSpinner } from "./spinner";

export default class DataLoader extends React.PureComponent<
  {
    pathname: string,
    component: React.Node,
    timeout: number,
    onDataFetch: (data: any) => void,
    passDataToComponent: boolean
  },
  {
    data: any,
    passedDelay: boolean
  }
> {
  state = {
    data: null,
    passedDelay: false
  };
  delayTimer = null;

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pathname !== this.props.pathname) {
      this.fetchData();
    }
  }

  fetchData() {
    if (this.props.pathname && this.props.pathname.length > 0) {
      this.delayTimer = setTimeout(
        function () {
          if (!this.state.data) {
            this.setState({ passedDelay: true });
          }
        }.bind(this),
        this.props.timeout || 3000
      );
      if (this.state.data) {
        this.setState({ data: null });
      }
      callAPI(this.props.pathname).then(data => {
        if (data) {
          if (this.delayTimer) {
            clearTimeout(this.delayTimer);
          }
          if (this.props.onDataFetch) {
            this.props.onDataFetch(data);
          }
          this.setState({ data, passedDelay: false });
        }
      });
    }
  }

  render() {
    const { component: TargetComponent, ...rest } = this.props;
    if (this.state.data) {
      var propsToPass = {};
      if (this.props.passDataToComponent) {
        propsToPass = {
          ...this.state.data,
          ...rest
        };
      } else {
        propsToPass = rest;
      }
      return <TargetComponent {...propsToPass} />;
    }
    if (this.state.passedDelay) {
      return (
        <ModalContainer>
          <BigSpinner />
        </ModalContainer>
      );
    }
    return null;
  }
}

DataLoader.defaultProps = {
  passDataToComponent: true
};
