// @flow

import * as React from "react";
import styled, { keyframes } from "styled-components";
import { slideInRight, slideInUp, pulse } from "react-animations";
import ImageZoom from "react-medium-image-zoom";
import Tooltip from "@tippy.js/react";
import "tippy.js/themes/light.css";
import { Title, Body } from "../text";
import Button from "../button";
import { Row } from "../container";

export const QuestionContainer = styled.div`
  border: solid 1px ${(props) => props.theme.color.disabled};
  border-radius: 5px 0 0 5px;
  flex: ${(props) => (props.isInTestResult ? 1 : 0.5)};
  max-width: ${(props) => (props.isInTestResult ? "100%" : "50%")};
  ${(props) => (props.isInTestResult ? "" : "border-right: none")};
  background-color: ${(props) => props.theme.color.raisedBackground};

  @media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    flex: 1;
    max-width: 100%;
    border: none;
  }
`;

export const ExplanationContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${(props) => props.theme.color.disabled};
  border-radius: 0 5px 5px 0;
  background-color: ${(props) => props.theme.color.learnCardExplanation};
  border-left: none;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  padding: 20px;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;

  @media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    position: relative;
    left: 0;
  }
`;

export const ExplanationContainerInTestResult = styled(ExplanationContainer)`
  position: relative;
  left: 0;
`;

const slidesInAnimation = keyframes`${slideInRight}`;
export const ExplanationTextContainer = styled.div`
  animation: 0.5s ${slidesInAnimation};
`;

const slidesUpAnimation = keyframes`${slideInUp}`;
const pulseAnimation = keyframes`${pulse}`;
export const LockContainer = styled.div`
  width: 100%;
  text-align: center;
  img {
    animation: 3s ${pulseAnimation};
  }
  p {
    margin-top: 20px;
    text-align: center;
    color: white;
    animation: 1s linear ${slidesUpAnimation};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1000px;
  min-width: ${(props) => (props.isInTestResult ? "0px" : "960px")};
  position: relative;
  margin-bottom: 2em;

  @media only screen and (min-device-width: 320px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: ${(props) =>
      props.isInTestResult ? "100%" : "calc(100% - 40px)"};
    min-width: ${(props) => (props.isInTestResult ? "0px" : "50%")};
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    flex-direction: column;
  }
`;
export const Header = styled(Row)`
  padding: 10px;
`;
export const QuestionCode = styled(Title)`
  margin: 0;
`;

export const ZoomableImage = ({
  src,
  className,
}: {
  src: string,
  className: string,
}) => (
  <ImageZoom
    defaultStyles={{
      zoomImage: {
        objectFit: "contain",
      },
      overlay: {
        backgroundColor: "black",
      },
    }}
    image={{
      src,
      className,
    }}
    zoomImage={{
      src,
    }}
  />
);
export const QuestionImage = styled(ZoomableImage)`
  cursor: pointer;
  min-width: 100%;
  width: 100%;
  background-color: white;
  background-image: ${(props) =>
    props.src ? null : `url(${require("../no_image.svg")})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
`;
export const QuestionVideo = styled.video.attrs({
  controls: true,
  controlsList: "nodownload",
})`
  cursor: pointer;
  min-width: 100%;
  width: 100%;
  background-color: white;
  background-image: ${(props) =>
    props.src ? null : `url(${require("../no_image.svg")})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
`;
export const QuestionText = styled(Body)`
  padding: 10px 10px 20px 10px;
`;
export const AnswersContainer = styled.div`
  padding: 10px;
`;
export const Answer = styled(Row)`
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  padding: 20px 5px;
  border: solid 1px ${(props) => props.theme.color.disabled};
  border-color: ${(props) =>
    props.isCorrect && props.showExplanation
      ? props.theme.color.secondary
      : props.theme.color.disabled};
  border-radius: 5px;
  :hover {
    background-color: ${(props) => props.theme.color.primaryLight};
  }

  & > p {
    margin: 0;
    flex: 1;
  }

  & > input {
    margin-right: 10px;
    width: 20px;
  }
`;
export const LockImage = styled.img.attrs({
  src: require("../lock.svg"),
})`
  width: 50px;
  height: 50px;
  align-self: center;
`;
export const CheckImage = styled.img.attrs({
  src: require("../check.png"),
})`
  width: 20px;
  margin-left: 10px;
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  display: block;
`;
export const LearnButton = styled(Button)`
  border-radius: 0;
  flex: ${(props) => (props.stretch ? 1 : 0.5)};
`;
export const ButtonsRow = styled(Row)`
  margin-top: 30px;
`;
export const CheckmarkTooltipContent = styled(Body)`
  margin: 0;
  padding: 0;
`;
export const CheckmarkTooltip = styled(Tooltip).attrs({
  placement: (props) => props.position || "right",
  inertia: true,
  arrow: true,
  animation: "scale",
  size: "large",
  className: "progress-tooltip",
  theme: (props) => props.theme.name,
})``;

export const NumberAnswerContainer = styled(Row)`
  border: none;
  display: flex;
  flex-direction: column;
  padding: 20px;

  & > input {
    width: 100%;
    padding: 10px;
    flex: 1;
  }
  & > div {
    margin-top: 10px;
  }
`;

export const ExplanationText = styled(Body)`
  & > i {
    font-style: normal;
    font-weight: bold;
  }
`;

export const AnswerImage = styled.img`
  width: 100%;
  max-height: 200px;
  object-fit: contain;
`;
