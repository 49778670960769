// @flow
import React from "react";
import styled, { keyframes } from "styled-components";
import { Body } from "../text";
import { Container } from "../container";
import { connect } from "react-redux";
import CategoryTotalProgress from "./category_progress";

import type { AppStateType } from "../../redux/reducers";
import { withState } from "recompose";
import type { ProgressType } from "../dashboard/overall_progress";

const scaleUp = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
`;

const Image = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${props => props.icon});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  height: 240px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => {
    if (!props.colored) {
      return 0.5;
    }
    return 1;
  }}
  min-height: 1px;
`;

const ProgressBox = styled.div`
  background-color: ${props => props.color};
  color: white;
  flex: 0.2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-family: ${props => props.theme.font.family};
  cursor: ${props => (props.count > 0 ? "pointer" : "not-allowed")};
`;

const ProgressBoxesContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
`;

const ProgressInfoContainer = styled.div`
  background-color: ${props => props.color};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(100% - 40px);
  padding: 10px;
`;

const ProgressInfoText = styled(Body)`
  max-width: 22ch;
  text-align: center;
  color: white;
`;

const CategoryBoxContainer = styled(Container)`
  align-items: stretch;
  margin: 0 10px 30px 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-height: 300px;
  border-radius: 5px;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  :hover {
    transform: scale(1.1);
    animation: ${scaleUp} 0.3s linear;
  }
`;

const CategoryTitleContainer = styled.div`
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  background-color: ${props => props.theme.color.categoryBox};
`;

type CategoryType = {
  code: string,
  icon: string,
  title: string
};

type CategoryBoxPropsType = {
  category: CategoryType,
  progress: Array<ProgressType>,
  total: number,
  messages: {
    darkGreenInfo: string,
    greenInfo: string,
    orangeInfo: string,
    redInfo: string,
    grayInfo: string
  },
  hovered?: string,
  colored?: boolean,
  onClick?: (code: string) => void,
  onClickProgress?: (
    code: string,
    progress: string,
    progressURL: string
  ) => void,
  onMouseEnterCategory?: (code: string) => void,
  onMouseExitCategory?: (code: string) => void,
  setHovered: (hovered: string) => void
};
export const CategoryBox = ({
  category: { title, icon, code },
  progress,
  messages,
  hovered,
  colored,
  onClick,
  onClickProgress,
  onMouseEnterCategory,
  onMouseExitCategory,
  setHovered,
  total
}: CategoryBoxPropsType) => {
  const hasProgress =
    progress &&
    progress.length > 0 &&
    progress.map(p => p.count).reduce((a, b) => a + b, 0) > 0;
  var percent = 0;
  if (hasProgress) {
    const greenProgress = parseInt(
      progress.filter(p => p.color === "green")[0].count,
      10
    );
    const darkGreenProgress = parseInt(
      progress.filter(p => p.color === "darkGreen")[0].count,
      10
    );
    percent = Math.floor(((greenProgress + darkGreenProgress) / total) * 100);
  }
  return (
    <CategoryBoxContainer
      disabled={total === 0}
      onClick={() => onClick && onClick(code)}
      onMouseEnter={() => onMouseEnterCategory && onMouseEnterCategory(code)}
      onMouseLeave={() => onMouseExitCategory && onMouseExitCategory(code)}
    >
      <Image disabled={total === 0} icon={icon} colored={colored}>
        {hovered && (
          <ProgressInfoContainer color={hovered}>
            <ProgressInfoText>
              {messages[`${hovered}ProgressExplanation`].replace(
                "$progress",
                progress.filter(p => p.color === hovered)[0].count
              )}
            </ProgressInfoText>
          </ProgressInfoContainer>
        )}
        {!hovered && hasProgress && <CategoryTotalProgress percent={percent} />}
        {hasProgress && (
          <ProgressBoxesContainer>
            {["darkGreen", "green", "orange", "red", "gray"].map((color, i) => {
              const p = progress.filter(p => p.color === color)[0];
              return (
                <ProgressBox
                  count={p.count}
                  color={color}
                  key={i}
                  onClick={e => {
                    e.preventDefault();

                    if (onClickProgress) {
                      if (p.count > 0) {
                        onClickProgress(code, color, p.url);
                      }
                    }
                    e.stopPropagation();
                  }}
                  onMouseEnter={() => setHovered(color)}
                  onMouseLeave={() => setHovered("")}
                >
                  {progress.filter(p => p.color === color)[0].count}
                </ProgressBox>
              );
            })}
          </ProgressBoxesContainer>
        )}
      </Image>
      <CategoryTitleContainer>
        <Body align="center">{title}</Body>
      </CategoryTitleContainer>
    </CategoryBoxContainer>
  );
};

CategoryBox.defaultProps = {
  colored: true,
  hovered: "",
  onClick: () => {},
  onClickProgress: () => {},
  onMouseEnterCategory: () => {},
  onMouseExitCategory: () => {}
};

const WithState = withState("hovered", "setHovered", "")(CategoryBox);

const mapStateToProps = (state: AppStateType) => ({
  messages: state.language.messages
});

export default connect(mapStateToProps)(WithState);
