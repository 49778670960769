// @flow

import * as React from "react";
import { callAPI } from "../utils/api";
import { connect } from "react-redux";
import type { AppStateType } from "../redux/reducers";

export class ActivityReporter extends React.Component {
  constructor(props) {
    super(props);
    this.currentTime = new Date();
    this.loopId = null;
  }

  componentDidMount() {
    this.startActivityLoop();

    if (typeof window !== "undefined") {
      window.onfocus = function() {
        if (!this.loopId) {
          this.currentTime = new Date();
          this.startActivityLoop();
        }
      }.bind(this);

      window.onblur = function() {
        clearInterval(this.loopId);
        this.loopId = null;
      }.bind(this);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authenticated !== this.props.authenticated) {
      if (this.loopId) {
        clearInterval(this.loopId);
        this.loopId = null;
      }
      if (this.props.authenticated) {
        this.currentTime = new Date();
        this.startActivityLoop();
      }
    }
  }

  startActivityLoop() {
    if (typeof window !== "undefined") {
      this.loopId = setInterval(
        function() {
          const now = new Date();
          const duration = now.getTime() - this.currentTime.getTime();
          if (duration > 1000 && this.props.authenticated) {
            callAPI("/online", { duration: duration });
          }
          this.currentTime = new Date();
        }.bind(this),
        60000
      );
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state: AppStateType) => ({
  authenticated: state.user.authenticated
});

export default connect(mapStateToProps)(ActivityReporter);
