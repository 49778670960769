// @flow

import React from "react";
import styled from "styled-components";
import type { LearningSetState } from "../../redux/reducers/flavour";

export const StyledSelectContainer = styled.div`
  height: 34px;
  background-color: #eff3f6;
  overflow: hidden;
  border: solid;
  border: 1px solid rgba(27, 31, 35, 0.2);
  border-radius: 0.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  background-image: linear-gradient(-180deg, #fafbfc 0%, #eff3f6 90%);
  background-repeat: repeat-x;
  background-position: -1px -1px;
  background-size: 110% 110%;
`;

export const StyledSelect = styled.select`
  font-family: ${(props) => props.theme.font.family};
  font-size: 17px;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZD0iTTc1Ljc1LDM3LjQ2NmMwLjQyNSwwLjQyNSwwLjU1MiwxLjA2MywwLjMyMiwxLjYxOEM3NS44NDIsMzkuNjM5LDc1LjMwMSw0MCw3NC43LDQwbC00OS40MDEsMCAgIGMtMC42LDAtMS4xNDItMC4zNjItMS4zNzEtMC45MTdjLTAuMjMtMC41NTUtMC4xMDMtMS4xOTMsMC4zMjItMS42MThsMjMuNDQxLTIzLjQ0MWMxLjI3NS0xLjI3NSwzLjM0Mi0xLjI3NSw0LjYxOCwwTDc1Ljc1LDM3LjQ2NiAgIHoiPjwvcGF0aD48cGF0aCBkPSJNMjQuMjQ5LDYyLjUzNGMtMC40MjUtMC40MjUtMC41NTItMS4wNjMtMC4zMjItMS42MThDMjQuMTU3LDYwLjM2MSwyNC42OTgsNjAsMjUuMjk4LDYwaDQ5LjQwMyAgIGMwLjYwMSwwLDEuMTQyLDAuMzYxLDEuMzcyLDAuOTE2YzAuMjI5LDAuNTU1LDAuMTAzLDEuMTkzLTAuMzIyLDEuNjE4TDUyLjMwOSw4NS45NzdjLTEuMjc1LDEuMjc1LTMuMzQzLDEuMjc0LTQuNjE4LDAgICBMMjQuMjQ5LDYyLjUzNHoiPjwvcGF0aD48L2c+PC9zdmc+);
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  border: none;
  width: 100%;
  color: #24292e;
  -webkit-appearance: none;
`;

type SetsPickerPropsType = {
  sets?: Array<LearningSetState>,
  selectedSet: LearningSetState,
  onChange?: (set: LearningSetState) => void,
  selectedLanguage: string
};
const SetsPicker = ({
  sets = [],
  onChange = () => {},
  selectedSet,
  selectedLanguage = "en"
}: SetsPickerPropsType) => {
  if (sets.length <= 1) {
    return null;
  }

  return (
    <StyledSelectContainer>
      <StyledSelect value={selectedSet.code} onChange={onChange}>
        {sets.map((s, i) => (
          <option
            key={i}
            value={s.code}>{`${s.code} (${s.names[selectedLanguage]})`}</option>
        ))}
      </StyledSelect>
    </StyledSelectContainer>
  );
};

export default SetsPicker;
