// @flow

import * as React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Grid from "styled-components-grid";

export const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  align-items: center;
  padding: ${(props) => props.padding || 0};
`;

export const RaisedContainer = styled.div`
  background-color: ${(props) => props.theme.color.raisedBackground};
  width: 60%;
  max-width: 900px;
  margin: 2em auto;
  border-radius: 8px;
  box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12),
    0 7px 10px -5px rgba(0, 0, 0, 0.15);

  @media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    width: 90vw;
  }

  @media only screen and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 100vw;
    margin: 0;
    margin-top: 1em;
    border-radius: 0;
  }
`;

export const FullWidthRaisedContainer = styled(RaisedContainer)`
  width: 100%;
  padding: 20px;
`;

export const ItemsCenteredContainer = styled(Container)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: stretch;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.backgroundColor || "rgba(255, 255, 255, 0.94)"};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 3em;
  padding-top: 3em;
`;

export const GridCenterContainer = styled(Grid.Unit).attrs({
  size: { tablet: 0.9, desktop: 0.6, mobile: 1 }
})`
  max-width: 833px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.center
      ? "center"
      : props.start
      ? "flex-start"
      : props.end
      ? "flex-end"
      : "space-between"};
`;

const modalRoot = document.getElementById("modal-root");

export class Modal extends React.Component<{}> {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
