// @flow

export const supportedLanguages = [
  {
    id: "de",
    icon: require("../components/de.svg"),
    name: "Deutsch"
  },
  {
    id: "fr",
    icon: require("../components/fr.svg"),
    name: "Français"
  },
  {
    id: "it",
    icon: require("../components/it.svg"),
    name: "Italiano"
  },
  {
    id: "en",
    icon: require("../components/en.svg"),
    name: "English"
  }
];

/*eslint no-useless-escape: "off"*/
const escapeRegex = (value: string) =>
  value.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");

export const stringWithFormat = (message: string, params: Object) => {
  var result = message || "";
  Object.keys(params).forEach(function(key) {
    result = result.replace(new RegExp(escapeRegex(key), "g"), params[key]);
  });
  return result;
};
