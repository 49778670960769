// @flow

export type UserStateType = {
  id: string,
  email: string,
  code: string,
  language: string,
  token: string,
  authenticated: boolean,
  coaching: {
    teacher: string,
    student: string
  },
  validVouchersFlavours: Array<{
    _id: string, // name of the flavour, e.g., ch_auto_premium
    title: { [key: string]: string }, // the title in multiple languages
    codes: string[] // the list of voucher codes
  }>,
  showTruckPlus: boolean
};

export const initialState: UserStateType = {
  id: "",
  email: "",
  code: "",
  language: "en",
  token: "",
  authenticated: false,
  coaching: {
    teacher: "",
    student: "",
    status: ""
  },
  validVouchersFlavours: [],
  showTruckPlus: false
};

export type SetUserActionType = {
  type: "SET_USER",
  payload: UserStateType
};

export default (
  state: UserStateType = initialState,
  action: SetUserActionType
) => {
  switch (action.type) {
    case "SET_USER": {
      return action.payload;
    }
    default:
  }
  return state;
};
