// @flow

import * as React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import type { AppStateType } from "../redux/reducers";

const ElearningHelmet = ({
  authenticated,
  language = "en"
}: {
  authenticated: boolean,
  language: string
}) => {
  console.log(language);
  return (
    <Helmet>
      <html lang={language} translate="no" content="notranslate" />
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-1064425490"
      />
      <script>
        {`
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
  
      gtag('config', 'AW-1064425490');
    `}
      </script>
      <script>{`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"25023712"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}</script>
      <script>
        {`
      !function (f, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
          n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }; if (!f._fbq) f._fbq = n;
        n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[ 0 ]; s.parentNode.insertBefore(t, s)
      }(window,
        document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${process.env.REACT_APP_FB_PIXEL_ID}'); // Insert your pixel ID here.
      fbq('track', 'PageView');
    `}
      </script>
      <noscript>
        {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${process.env.REACT_APP_FB_PIXEL_ID}&ev=PageView&noscript=1"/>`}
      </noscript>
      <meta name="google" content="notranslate" />
    </Helmet>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  authenticated: state.user.authenticated,
  language: state.language.selected
});

export default connect(mapStateToProps)(ElearningHelmet);
