// @flow

import * as React from "react";
import styled, { keyframes } from "styled-components";
import QRReader from "react-qr-reader";
import { Body } from "../components/text";
import { Link } from "../components/button";
import { withRouter } from "react-router-dom";
import InfoBox from "../components/infobox";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { flavourWithLanguage } from "../redux/reducers/flavour";
import { callAPI } from "../utils/api";
import { setFlavour } from "../redux/actions/flavour";
import type { AppStateType } from "../redux/reducers";
import { fadeInDown, merge, flipInX } from "react-animations";

const Container = styled.div`
  max-width: 1000px;
  margin: 20px auto;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
const appearAnimation = keyframes`${merge(fadeInDown, flipInX)}`;
const StyledReader = styled(QRReader)`
  width: 100%;
  max-width: 300px;
  margin: 1em auto;
  animation: 1s ${appearAnimation};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
`;
const QRLogo = styled.img`
  width: 50px;
  height: 50px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-bottom: 20px;
`;

export type QRCodePropsType = {
  history: {
    push: (path: string) => void
  },
  qrMessage: string,
  setQrMessage: (qrMessage: string) => void,
  onScan: (data: string) => void,
  messages: Object
};
const QRCode = ({
  history,
  qrMessage,
  setQrMessage,
  onScan,
  messages
}: QRCodePropsType) => (
  <Container>
    <QRLogo src={require("../components/qrcode.png")} />
    <Body>{messages["scanQRCode"]}</Body>
    {qrMessage && <InfoBox>{qrMessage}</InfoBox>}
    <StyledReader
      delay={1000}
      onError={e => setQrMessage(messages[e.name])}
      onScan={onScan}
    />
    <Link
      href="#"
      onClick={e => {
        e.preventDefault();
        history.push("/");
      }}
    >
      {messages["Cancel"]}
    </Link>
  </Container>
);

export const getValueFromQuery = (param: string, url: string): ?string => {
  const components = url.split(`${param}=`);
  if (components[1] && components[1].length > 0) {
    const paramComponents = components[1].split("&");
    if (paramComponents.length > 0) {
      const value = paramComponents[0];
      if (value) {
        return value;
      }
    }
    return null;
  }
};

export const voucherFlavourFromQRCodeLink = (qrLink: string): ?Object => {
  // https://truck.app.link/truck?voucher=JOPJ-OI6I-VWKO&teacher=L025&flavour=ch_truck_premium

  const voucher = getValueFromQuery("voucher", qrLink);
  if (voucher) {
    const flavour = getValueFromQuery("flavour", qrLink);
    return {
      voucher,
      flavour
    };
  }

  return null;
};

// get the messages and flavour props from redux
const mapStateToProps = (state: AppStateType): any => ({
  messages: state.language.messages,
  selectedLanguage: state.language.selected,
  flavour:
    state.flavour && state.flavour.name
      ? flavourWithLanguage(state.flavour, state.language.selected)
      : null
});
const WithState = compose(
  withState("qrMessage", "setQrMessage", ""),
  lifecycle({
    componentDidMount() {
      const props = this.props;
      if (!props.flavour) {
        // if the scan page is accessed directly, flavour will be empty, so fetch first on mount
        callAPI(props.location.pathname.replace("/scan", "")).then(result =>
          props.dispatch(setFlavour(result))
        );
      }
    }
  }),
  withHandlers({
    onScan: props => data => {
      if (data) {
        const voucherFlavour = voucherFlavourFromQRCodeLink(data);
        if (voucherFlavour) {
          const { voucher, flavour } = voucherFlavour;
          var flavourToUse = flavour;
          if (!flavourToUse) {
            flavourToUse =
              props.flavour && props.flavour.name
                ? props.flavour.name
                : "ch_auto_premium";
          }
          props.history.push(
            `/flavour/${flavourToUse}?voucher=${voucher}&lang=${
              props.selectedLanguage
            }`
          );
        }
      }
    }
  })
)(QRCode);

export default withRouter(connect(mapStateToProps)(WithState));
