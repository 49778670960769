// @flow

import * as React from "react";
import { ModalContainer } from "./container";
import styled from "styled-components";
import { CloseButton } from "./button";
import Grid from "styled-components-grid";
import { Body } from "./text";
import type { AppStateType } from "../redux/reducers";
import { supportedLanguages } from "../utils/locale";
import { connect } from "react-redux";
import { setLanguage } from "../redux/actions/language";

const Container = styled(ModalContainer)`
  padding: 30px;
  max-width: 600px;
  width: 100%;
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  bottom: 0;
  box-shadow: -9px 11px 30px -5px rgba(0, 0, 0, 0.47);

  @media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: calc(100% - 60px);
  }
`;
const GridContainer = styled(Grid)`
  width: 100%;
  height: 100%;
  align-items: center;
`;
const ModalCloseButton = styled(CloseButton)`
  position: fixed;
  top: 10px;
  right: 10px;
`;
const LanguageFlag = styled.img`
  cursor: pointer;
  max-width: calc(100% - 40px);
  max-height: 80px;
  width: auto;
  height: auto;
  object-fit: cover;
  margin: 20px;
  -webkit-box-shadow: 0px 23px 49px -23px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 0px 23px 49px -23px rgba(0, 0, 0, 0.47);
  box-shadow: 0px 23px 49px -23px rgba(0, 0, 0, 0.47);
  transition: 0.1s ease-in-out;
  :hover {
    transform: scale(1.1);
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: 40px;
  }
`;
const LanguageContainer = styled(Grid.Unit)`
  text-align: center;
`;
type LanguageType = {
  id: string,
  name: string,
  icon: string
};
type LanguagesSelectorPropsType = {
  languages: Array<LanguageType>,
  onClickLanguage: (language: string) => void,
  onClickClose: () => void,
  className?: string
};
export const LanguagesSelector = (props: LanguagesSelectorPropsType) => (
  <Container className={props.className}>
    <ModalCloseButton onClick={props.onClickClose} />
    <GridContainer halign="center">
      {props.languages.map((l, i) => (
        <LanguageContainer
          key={i}
          size={{
            desktop: 1 / 4,
            mobile: 1 / 2,
            tablet: 1 / 4
          }}
          onClick={() => {
            props.onClickLanguage(l.id);
            props.onClickClose();
          }}
        >
          <LanguageFlag src={l.icon} />
          <Body align="center">{l.name}</Body>
        </LanguageContainer>
      ))}
    </GridContainer>
  </Container>
);

/**
 * ======================================================
 * Section below is to enhance the LanguagesSelector component
 * ======================================================
 */
// get the name of the languages from redux
const mapStateToProps = (state: AppStateType) => ({
  languages: supportedLanguages.filter(
    l => state.language.supportedLanguages.indexOf(l.id) > -1
  )
});

// set the language in redux on click
const mapDispatchToProps = (dispatch: any) => ({
  onClickLanguage: (language: any) => dispatch(setLanguage(language))
});
const Connected = connect(mapStateToProps, mapDispatchToProps)(
  LanguagesSelector
);

export default Connected;
