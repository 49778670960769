// @flow

import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 0.1em 5em;
  font-size: ${(props) => props.theme.font.footer};
  font-family: ${(props) => props.theme.font.family};
  text-align: center;
`;

const Item = styled.ul`
  color: ${(props) => props.theme.color.text};
  list-style: none;
  padding: 0;

  li {
    display: inline-block;
    line-height: 1.2em;
    font-size: 0.7em;
  }
  li ~ li::before {
    content: "|";
    padding: 0 10px;
    color: ${(props) => props.theme.color.text};
  }

  & > li > a {
    color: ${(props) => props.theme.color.text};
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    li ~ li::before {
      content: "";
      padding: 0;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    li ~ li::before {
      content: " ";
      padding: 0;
    }
  }
`;

const FooterComponent = () => (
  <Container>
    <Item>
      <li>
        <strong>Swift Management AG</strong>
      </li>
      <li>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://goo.gl/maps/YPDceSGv9gF2">
          Feldbergstrasse 42 CH 4057 Basel
        </a>
      </li>
      <li>
        <a href="tel:+41613855555">0844120120</a>
      </li>
      <li>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:support@itheorie.ch">
          support@itheorie.ch
        </a>
      </li>
      {process.env.REACT_APP_COMMIT &&
        process.env.ENVIRONMENT === "staging" && (
          <li>{process.env.REACT_APP_COMMIT}</li>
        )}
    </Item>
  </Container>
);

export default FooterComponent;
