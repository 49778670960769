// @flow

import * as React from "react";
import styled from "styled-components";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Body } from "../components/text";
import LearnCard, {
  LearnDataProvider
} from "../components/learn_card.container";
import type { AppStateType } from "../redux/reducers";
import { compose, withProps } from "recompose";
import { callAPI } from "../utils/api";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
  padding-top: 2em;
`;
const TitleContainer = styled(Body)`
  text-align: center;
  margin-bottom: 2em;

  span ~ span::before {
    content: ">";
    padding: 0 10px;
    color: #dadada;
  }

  & > span > strong {
    font-weight: bolder;
  }
`;
const IndicesContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  overflow: auto;
  padding: 10px 20px;
  margin-top: 1em;
  text-align: center;
`;
const Number = styled.span`
  text-align: center;
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  padding: 20px 10px 0 10px;
  border-radius: 50%;
  font-family: ${props => props.theme.font.family};
  font-size: 17px;
  color: ${props =>
    props.active ? props.theme.color.text : props.theme.color.disabled};
  cursor: ${props => (props.active ? "pointer" : "not-allowed")};
  border: ${props => (props.current ? "solid 1px" : "none")};
  border-color: ${props => props.theme.color.primary};
  :hover {
    font-weight: ${props => (props.active ? "bolder" : "normal")};
    color: ${props =>
      props.active ? props.theme.color.primary : props.theme.color.disabled};
  }
`;

type TestPagePropsType = {
  remaining: number,
  title: string,
  index: number,
  total: number,
  messages: {
    quitTest: string,
    test: string
  },
  indices: Array<{
    index: number,
    clickable: boolean,
    apiPath: string
  }>,
  history: {
    push: (path: string, state?: any) => void
  },
  finishPath: string,
  setData: (data: any) => void,
  sessionId: string
};

export const TestPage = (props: TestPagePropsType) => {
  const {
    remaining = 30 * 60 + 32,
    index,
    indices,
    messages,
    finishPath,
    history,
    sessionId
  } = props;
  return (
    <Container>
      <TitleContainer>
        <span>
          <Link
            to={{
              pathname: "/dashboard",
              state: {
                selectedTab: "test"
              }
            }}
          >
            {messages.quitTest}
          </Link>
        </span>
        <span>
          {messages.test}{" "}
          <CountDownTimer
            sessionId={sessionId}
            remaining={remaining}
            onTimeout={() => {
              if (finishPath) {
                history.push(finishPath);
              }
            }}
          />
        </span>
      </TitleContainer>

      <LearnCard isInRunningTest={true} {...props} />

      {indices && (
        <IndicesContainer>
          {indices.map(i => (
            <Number
              active={i.clickable}
              key={i.index}
              current={i.index === index}
              onClick={() => {
                if (i.clickable) {
                  history.push(history.location.pathname, {
                    apiPath: i.apiPath
                  });
                }
              }}
            >
              {i.index + 1}
            </Number>
          ))}
        </IndicesContainer>
      )}
    </Container>
  );
};

class CountDownTimer extends React.Component<
  { remaining: number, onTimeout: () => void },
  { remaining: number }
> {
  countdownTimerId = null;

  constructor(props) {
    super(props);

    this.state = {
      remaining: props.remaining || 0
    };

    this.resetTimer();
  }

  resetTimer() {
    if (this.countdownTimerId) {
      clearInterval(this.countdownTimerId);
    }

    this.countdownTimerId = setInterval(
      function() {
        if (this.props.sessionId) {
          const remaining = Math.max(this.state.remaining - 1, 0);
          this.setState({
            remaining
          });
          callAPI(`/test/updatetime`, {
            sessionId: this.props.sessionId,
            remaining
          });
          if (remaining === 0) {
            if (this.countdownTimerId) {
              clearInterval(this.countdownTimerId);
            }
            if (this.props.onTimeout) {
              this.props.onTimeout();
            }
          }
        }
      }.bind(this),
      1000
    );
  }

  componentWillUnmount() {
    if (this.countdownTimerId) {
      clearInterval(this.countdownTimerId);
    }
  }

  render() {
    const remainingString = `${Math.floor(this.state.remaining / 60)
      .toString()
      .padStart(2, "0")}:${(this.state.remaining % 60)
      .toString()
      .padStart(2, "0")}`;
    return <strong>({remainingString})</strong>;
  }
}

const Enhanced = compose(
  withProps(props => ({
    remaining: props.duration - props.elapsed,
    finishPath: props.resultURL
  }))
)(TestPage);

const mapStateToProps = (state: AppStateType) => ({
  messages: state.language.messages
});
const ConnectedTestPage = connect(mapStateToProps)(Enhanced);

const WithData = props => (
  <LearnDataProvider
    location={props.location}
    Component={ConnectedTestPage}
    {...props}
  />
);

const WithRouter = withRouter(WithData);

export default WithRouter;
