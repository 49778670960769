// @flow

import styled from "styled-components";

export const SmallScreen = styled.div`
  display: none;

  @media only screen and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    display: inherit;
  }
`;

export const WideScreen = styled.div`
  @media only screen and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    display: none;
  }
`;

export const smallScreenQuery =
  "only screen and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)";
export const wideScreenQuery = "screen and (min-device-width: 750px)";
