// @flow

import { setFlavour } from "./flavour";
import { setOverallProgress } from "./overall_progress";
import { setRunningTest, setTestsHistory } from "./tests";

export const setDashboardData = (data: any) => (dispatch: any) => {
  dispatch(setFlavour(data.flavour));
  dispatch(setOverallProgress(data.overallProgress));
  dispatch(setRunningTest(data.currentTest));
  dispatch(setTestsHistory(data.testsHistory));
  dispatch({
    type: "SET_GROUPED_CATEGORIES",
    payload: data.groupedCategories
  });
};
