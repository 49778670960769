// @flow

import * as React from "react";
import styled, { keyframes } from "styled-components";
import Tooltip from "@tippy.js/react";

import type { AppStateType } from "../redux/reducers";
import { connect } from "react-redux";
import LanguagesSelector from "./languages_selector";
import { withState, compose, withHandlers } from "recompose";
import { Modal, Row, ModalContainer } from "./container";
import { Link, withRouter } from "react-router-dom";
import { Body } from "./text";

import { bounceInRight, bounceOutRight } from "react-animations";
import { IconButton, CloseButton } from "./button";
import { logout } from "../redux/actions/user";
import { wideScreenQuery, smallScreenQuery } from "./screen";
import Media from "react-media";

const bounceInAnimation = keyframes`${bounceInRight}`;
const bounceOutAnimation = keyframes`${bounceOutRight}`;
const AnimatedLanguagesSelector = styled(LanguagesSelector)`
  animation: 0.5s
    ${(props) =>
      props.show
        ? props.startClosing
          ? bounceOutAnimation
          : bounceInAnimation
        : bounceOutAnimation};
`;

const Container = styled.div`
  padding: 10px;
  height: 40px;
  background-color: ${(props) => props.theme.color.raisedBackground};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12),
    0 7px 10px -5px rgba(0, 0, 0, 0.15);
  perspective: 100px;
`;
const InnerContainer = styled.div`
  width: 60%;
  margin 0 auto; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    width: 100%;
  }
`;
const StyledLink = styled(Link)`
  display: block;
  height: 100%;
`;
export const Logo = styled.img.attrs((props) => {
  return {
    src:
      props.theme.name === "dark"
        ? require("./swift-on-dark.svg")
        : require("./swift.svg")
  };
})`
  display: block;
  height: 70%;
  width: 98px;
  padding: 10px;
`;
const Flag = styled.img`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin-right: 5px;
  border: solid 1px white;
  cursor: pointer;
`;
const RightNavigationContainer = styled(Row)`
  padding: 0 30px;

  & > div {
    margin-left: 15px;
  }

  & > div > a:hover {
    transform: scale(1);
  }

  & > div > p {
    color: white;
    margin: 0 5px;
  }
`;
const RightNavigationItem = styled(Row)`
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  &:hover {
    background-color: #048bc7;
  }

  & > p {
    color: white;
    margin: 0 5px;
  }

  @media only screen and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    margin-bottom: 10px;
  }
`;

const BurgerMenu = styled.img.attrs((props) => {
  return {
    src:
      props.theme.name === "dark"
        ? require("./burger.svg")
        : require("./burger-light.svg")
  };
})`
  width: 20px;
  height: 20px;
`;
const ModalCloseButton = styled(CloseButton)`
  position: fixed;
  top: 10px;
  right: 10px;
`;
const MenusContainer = styled(ModalContainer)`
  background-color: ${(props) => props.theme.color.primary};
  display: flex;
  flex-direction: column;
`;
const ToggleButton = (props) => (
  <Tooltip
    key={props.theme}
    placement="bottom"
    content={
      props.theme === "dark"
        ? props.messages.lightTheme
        : props.messages.darkTheme
    }
    inertia={true}
    arrow={true}
    theme="light">
    <RightNavigationItem onClick={props.toggleTheme}>
      <IconButton
        {...props}
        selectedIcon={
          props.theme === "dark" ? require("./sun.svg") : require("./moon.svg")
        }
        normalIcon={
          props.theme === "dark" ? require("./sun.svg") : require("./moon.svg")
        }
      />
      {props.showTitle && (
        <Body>
          {props.theme === "dark"
            ? props.messages.lightTheme
            : props.messages.darkTheme}
        </Body>
      )}
    </RightNavigationItem>
  </Tooltip>
);
const ThemeButton = connect(
  (state) => ({ theme: state.theme.name }),
  (dispatch) => ({
    toggleTheme: () => dispatch({ type: "TOGGLE_THEME" })
  })
)(ToggleButton);

const DesktopNavItem = ({ title, icon, onClick, iconComponent }) => (
  <Tooltip
    key={title}
    placement="bottom"
    content={title}
    inertia={true}
    arrow={true}
    theme="light">
    <RightNavigationItem onClick={onClick}>
      {iconComponent || <IconButton normalIcon={icon} />}
    </RightNavigationItem>
  </Tooltip>
);

type LanguageType = {
  id: string,
  name: string,
  icon: string
};
type HeaderPropsType = {
  selectedLanguage: LanguageType,
  showLanguageSelector: boolean,
  onClickLanguageSelector: (showLanguageSelector: boolean) => void,
  startClosingLanguage: boolean,
  setStartClosingLanguage: (startCLosingLanguage: boolean) => void,
  isLoggedIn: boolean,
  onClickLogout: () => void,
  onClickSearch: () => void,
  messages: any,
  isMenuOpen: boolean,
  onClickMenu: () => void,
  onClickProducts: () => void,
  flavours: Array<Object>,
  languages: Array<Object>
};
export const Header = ({
  selectedLanguage,
  showLanguageSelector,
  onClickLanguageSelector,
  startClosingLanguage,
  setStartClosingLanguage,
  isLoggedIn,
  onClickLogout,
  onClickSearch,
  messages,
  isMenuOpen,
  onClickMenu,
  flavours,
  languages,
  onClickProducts
}: HeaderPropsType) => (
  <Container>
    <InnerContainer>
      <StyledLink to={isLoggedIn ? "/dashboard" : "/"}>
        <Logo />
      </StyledLink>

      <Media query={wideScreenQuery}>
        {(matches) =>
          matches ? (
            <RightNavigationContainer>
              {isLoggedIn && (
                <DesktopNavItem
                  title={messages.search}
                  icon={require("./search.svg")}
                  onClick={onClickSearch}
                />
              )}
              {!isLoggedIn && flavours.length > 1 && (
                <DesktopNavItem
                  title={messages.products}
                  icon={require("./products.svg")}
                  onClick={onClickProducts}
                />
              )}
              {languages && languages.length > 1 && (
                <DesktopNavItem
                  title={selectedLanguage.name}
                  iconComponent={<Flag src={selectedLanguage.icon} />}
                  onClick={() => onClickLanguageSelector(!showLanguageSelector)}
                />
              )}
              <ThemeButton messages={messages} />
              {isLoggedIn && (
                <DesktopNavItem
                  title={messages.logout}
                  icon={require("./logout.svg")}
                  onClick={onClickLogout}
                />
              )}
            </RightNavigationContainer>
          ) : null
        }
      </Media>
      <Media query={smallScreenQuery}>
        {(matches) =>
          matches ? (
            <RightNavigationItem>
              <BurgerMenu onClick={onClickMenu} />
            </RightNavigationItem>
          ) : null
        }
      </Media>

      {showLanguageSelector && (
        <Modal>
          <AnimatedLanguagesSelector
            show={showLanguageSelector}
            startClosing={startClosingLanguage}
            onClickClose={() => {
              setStartClosingLanguage(true);
              setTimeout(() => {
                onClickLanguageSelector(!showLanguageSelector);
                setStartClosingLanguage(false);
              }, 300);
            }}
          />
        </Modal>
      )}

      {isMenuOpen && (
        <Modal>
          <MenusContainer>
            <ModalCloseButton onClick={onClickMenu} />
            {!isLoggedIn && (
              <RightNavigationItem onClick={onClickProducts}>
                <IconButton normalIcon={require("./products.svg")} />
                <Body>{messages.products}</Body>
              </RightNavigationItem>
            )}
            {isLoggedIn && (
              <RightNavigationItem
                onClick={(e) => {
                  onClickMenu(e);
                  onClickSearch(e);
                }}>
                <IconButton normalIcon={require("./search.svg")} />
                <Body>{messages.search}</Body>
              </RightNavigationItem>
            )}
            <RightNavigationItem
              onClick={() => onClickLanguageSelector(!showLanguageSelector)}>
              <Flag src={selectedLanguage.icon} />
              <Body>{selectedLanguage.name}</Body>
            </RightNavigationItem>
            <ThemeButton messages={messages} showTitle={true} />
            {isLoggedIn && (
              <RightNavigationItem
                onClick={(e) => {
                  onClickMenu(e);
                  onClickLogout(e);
                }}>
                <IconButton normalIcon={require("./logout.svg")} />{" "}
                <Body>{messages.logout}</Body>
              </RightNavigationItem>
            )}
          </MenusContainer>
        </Modal>
      )}
    </InnerContainer>
  </Container>
);

/**
 * ======================================================
 * Section below is to enhance the Header component
 * ======================================================
 */

const iconForLang = (language: string) => {
  if (language === "it") {
    return require("./it.svg");
  } else if (language === "de") {
    return require("./de.svg");
  } else if (language === "fr") {
    return require("./fr.svg");
  }
  return require("./en.svg");
};
const HeaderWithState = compose(
  withState("showLanguageSelector", "onClickLanguageSelector", false),
  withState("startClosingLanguage", "setStartClosingLanguage", false),
  withState("isMenuOpen", "setIsMenuOpen", false),
  withHandlers({
    onClickMenu: (props) => () => {
      props.setIsMenuOpen(!props.isMenuOpen);
    },
    onClickProducts: (props) => () => {
      props.setIsMenuOpen(false);
      props.history.push("/products");
    }
  })
)(Header);
const mapStateToProps = (state: AppStateType) => ({
  messages: state.language.messages,
  selectedLanguage: {
    id: state.language.selected,
    name: state.language.messages[state.language.selected],
    icon: iconForLang(state.language.selected)
  },
  isLoggedIn: state.user.authenticated,
  flavours: state.products,
  languages: state.language.supportedLanguages
});
const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  onClickLogout: (e) => {
    e.preventDefault();
    dispatch(logout());
  },
  onClickSearch: () => ownProps.history.push("/search")
});

const ConnectedHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderWithState);

export default withRouter(ConnectedHeader);
