// @flow

import * as React from "react";
import { Container, FullWidthRaisedContainer } from "../components/container";
import Button from "../components/button";
import { Body } from "../components/text";
import styled from "styled-components";
import { connect } from "react-redux";
import SectionTitle from "../components/section_title";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { compose, withHandlers } from "recompose";
import type { AppStateType } from "../redux/reducers";
import Space from "../components/spacing";

const FullWidthSection = styled(Container)`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
`;

const TimerIcon = styled.div`
  display: block;
  width: 100px;
  height: 100px;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(${(props) =>
    props.isInProgress
      ? require("../components/exam-timer-active.svg")
      : require("../components/exam-timer.svg")});
`;

const TestButtonTextContainer = styled.div`
  margin: 0 20px;
`;

const TestButtonTextIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
`;

const StatusImage = styled.img.attrs({
  src: (props) =>
    props.success
      ? require("../components/passed.svg")
      : require("../components/failed.svg"),
})`
  width: 50px;
  padding: 0 20px;
`;

const TestsHistoryContainer = styled.ul`
  padding: 10px;
  max-width: 80%;
  min-width: 20%;
  margin: 0 auto;
  align-self: center;
`;
const TestHistoryRow = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-bottom: solid 1px ${(props) => props.theme.color.disabled};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.color.primaryLight};
  }
`;
const TestHistoryText = styled(Body)`
  padding: 0;
  margin: 0 0 4px 0;
`;
const TestHistoryLabel = styled.span`
  color: #a5a5a5;
  display: inline-block;
  margin-right: 5px;
`;
const TestHistoryDetail = ({
  label,
  value,
}: {
  label: string,
  value: string,
}) => (
  <TestHistoryText>
    <TestHistoryLabel>{label}</TestHistoryLabel>
    {value}
  </TestHistoryText>
);

export type TestHistory = {
  id: string,
  date: string,
  score: number,
  duration: number,
  percentage: number,
  status: "passed" | "failed",
};
export type TestPageMessages = {
  startNewTest: string,
  percentage: string,
  score: string,
  testDate: string,
  passed: string,
  failed: string,
  continueTest: string,
  unfinishedTest: string,
  noUnfinishedTest: string,
  testsHistory: string,
};
type TestInProgress = {
  id: string,
  remaining: string,
};
type TestsPagePropsType = {
  testInProgress: TestInProgress,
  testsHistory: Array<TestHistory>,
  messages: TestPageMessages,
  onClickNewTest?: () => void,
  onClickTest?: (test: TestHistory) => void,
};
export const TestsPage = ({
  testsHistory,
  messages,
  testInProgress,
  onClickTest,
  onClickNewTest,
}: TestsPagePropsType) => {
  var buttonProps = { primary: true, onClick: onClickNewTest };
  if (testInProgress) {
    buttonProps = { ...buttonProps, secondary: true };
  }
  return (
    <FullWidthSection>
      <FullWidthRaisedContainer>
        <TestButtonTextIconContainer>
          <TimerIcon isInProgress={testInProgress ? true : false} />
          <TestButtonTextContainer>
            <Body>
              {testInProgress
                ? messages.unfinishedTest
                : messages.noUnfinishedTest}
            </Body>
            <Space v={10} />
            <Button {...buttonProps}>
              {testInProgress ? messages.continueTest : messages.startNewTest}
            </Button>
          </TestButtonTextContainer>
        </TestButtonTextIconContainer>

        {testsHistory && testsHistory.length > 0 && (
          <React.Fragment>
            <SectionTitle title={messages.testsHistory} />
            <TestsHistoryContainer>
              {testsHistory.map((t, i) => (
                <TestHistoryRow
                  key={i}
                  onClick={() => onClickTest && onClickTest(t)}
                >
                  <StatusImage success={t.status === "passed"} />
                  <div>
                    <TestHistoryDetail
                      label={messages.percentage}
                      value={`${Math.ceil(t.percentage)}%`}
                    />
                    <TestHistoryDetail
                      label={messages.score}
                      value={`${t.score}`}
                    />
                    <TestHistoryDetail
                      label={messages.testDate}
                      value={moment(t.date).format("LLL")}
                    />
                  </div>
                </TestHistoryRow>
              ))}
            </TestsHistoryContainer>
          </React.Fragment>
        )}
      </FullWidthRaisedContainer>
    </FullWidthSection>
  );
};
TestsPage.defaultProps = {
  values: {
    testsHistory: [],
  },
};

const Enhanced = compose(
  withHandlers({
    onClickNewTest: (props) => (event) => {
      props.history.push(`/learn/test`);
    },
    onClickTest: (props) => (test) => {
      props.history.push(test.url);
    },
  })
)(TestsPage);

const mapStateToProps = (state: AppStateType) => ({
  messages: state.language.messages,
  testsHistory: state.tests.history,
  testInProgress: state.tests.testInProgress,
});
const ConnectedTestsPage = connect(mapStateToProps)(Enhanced);

export default withRouter(ConnectedTestsPage);
